import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { HttpErrorResponse } from "@angular/common/http";
import { AuthService } from "src/app/services/auth.service";
import { MatExpansionPanel } from "@angular/material";
import { GridApi } from "ag-grid-community";
import { SearchService } from "src/app/services/search.service";
import { UserService } from "src/app/services/user.service";
import { TemplateCategoryService } from "src/app/services/template-category-service";
import { NotificationService } from "src/app/services/notification.service";
import { TemplateCategory } from "src/app/models/template-category,model";
import { DialogService } from "src/app/services/dialog.service";
import { AssignUpdateTeamModalComponent } from "./assign-update-team/assign-update-team-modal.component";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { SpinnerService } from "src/app/services/spinner.service";
import { OrganizationService } from "src/app/services/organization.service";
import { InviteNewUserModal } from "./invite-new-user-modal/invite-new-user-modal.component";
import { SiteService } from "src/app/services/site.service";
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: "app-user-team-modal",
  templateUrl: "./user-team-modal.component.html",
  styleUrls: ["./user-team-modal.component.css"],
})
export class UserTeamComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  chosenRoleList: string[] = [];
  wholeRoleList: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  roleCtrl = new FormControl();
  filteredRoles: Observable<string[]>;
  gridOptions: Object;

  public orgAdminOrganizations: Array<{
    OrganizationID: number;
    Name: string;
    StartDate: string;
    EndDate: string;
    IsAdmin: number;
  }> = [];
  protected selectedOrganizationID: number;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected userColumnDefs: any;
  public defaultColDef : object;
  constructor(
    private notificationService: NotificationService,
    private searchService: SearchService,
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
    protected userService: UserService,
    private dialogService: DialogService,
    private organizationService: OrganizationService,
    protected siteService: SiteService,
    protected messageService: MessageService,
  ) {
    this.gridOptions = {
      getRowClass: (params) => {
        if (params.data.Active === 0) {
          return 'row-inactive';
        }
      }
    }
    this.defaultColDef = {
      resizable: true,
  }
  }
  protected rowData;
  private gridApi: GridApi;
  private rowSelection;
  protected isRowSelected = false;
  protected isActive: boolean = true;
  protected selectedRow: any = [];


  ngOnInit(): void {
    setTimeout(() => {
      this.spinnerService.setIsLoading(true);
    }, 0);
    this.searchService.setPageName("User Org");

    //labels by message service
    if (localStorage.getItem('UserTeamLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('UserTeamLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "User Org",
        "UserOrganization"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('UserTeamLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    this.selectedOrganizationID = this.userService.organizationID;
    this.userService.userOrganizations.forEach((element) => {
      if (element.IsAdmin == 1) {
        this.orgAdminOrganizations.push(element);
      }
    });

    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.rowData = this.searchService.searchRecords;
        this.rowData.forEach((element) => {
          element.UserIDRef = element.UserID;
        });
        this.rowData = this.rowData.filter(row => row && row.userID !== null);
        this.gridApi.paginationGoToPage(0);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    this.searchService.searchInitiated.subscribe((event: boolean) => {
      if (event) {
        this.searchService.smartSearchPromise
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.rowData.forEach((element) => {
              element.UserIDRef = element.UserID;
            });
            this.rowData = this.rowData.filter(row => row && row.userID !== null);
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });



    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if (event) {
        this.searchService
          .smartSearchMultiSiteAPI(null)
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.rowData.forEach((element) => {
              element.UserIDRef = element.UserID;
            });
            this.rowData = this.rowData.filter(row => row && row.userID !== null);
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });

    this.userService.userDataChanged.subscribe((dataChanged: boolean) => {
      if (dataChanged) {
        this.searchService
          .smartSearchMultiSiteAPI(null)
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.rowData.forEach((element) => {
              element.UserIDRef = element.UserID;
            });
            this.rowData = this.rowData.filter(row => row && row.userID !== null);
            this.isRowSelected = false;
            this.isActive = true;
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    });
  }

  setColumnDef() {
    this.userColumnDefs = [
      {
        headerName: this.screenLabels["UserIDRef"]
          ? this.screenLabels["UserIDRef"]
          : "UserID",
        field: "UserIDRef",
        sortable: true,
        hide: true,
      },
      {
        headerName: this.screenLabels["OKTAID"]
          ? this.screenLabels["OKTAID"]
          : "OKTAID",
        field: "OKTAID",
        sortable: true,
        hide: true,
      },
      {
        headerName: this.screenLabels["EmployeeRef"]
          ? this.screenLabels["EmployeeRef"]
          : "EmployeeRef",
        field: "EmployeeRef",
        sortable: true,
        hide: true,
      },
      {
        headerName: "",
        field: "",
        width: 100,
        cellStyle: { color: "#589bf8" },
        hide: false,
        checkboxSelection: true,
        resizable: false,
        sortable: false,
        lockPosition: true,
      },
      {
        headerName: this.screenLabels["LastName"]
          ? this.screenLabels["LastName"]
          : "Last Name",
        field: "LastName",
        sortable: true,
      },
      {
        headerName: this.screenLabels["FirstName"]
          ? this.screenLabels["FirstName"]
          : "First Name",
        field: "FirstName",
        sortable: true,
      },
      {
        headerName: this.screenLabels["MiddleName"]
          ? this.screenLabels["MiddleName"]
          : "Middle Name",
        field: "MiddleName",
        sortable: true,
      },
      {
        headerName: this.screenLabels["Suffix"]
          ? this.screenLabels["Suffix"]
          : "Suffix",
        field: "Suffix",
        sortable: true,
      },
      {
        headerName: this.screenLabels["Email"]
          ? this.screenLabels["Email"]
          : "Email",
        field: "Email",
        sortable: true,
      },
      {
        headerName: this.screenLabels["Phone"]
          ? this.screenLabels["Phone"]
          : "Phone",
        field: "Phone",
        sortable: true,
      },
      {
        headerName: this.screenLabels["Status"]
          ? this.screenLabels["Status"]
          : "Status",
        field: "Status",
        hide: false,
        sortable: true,
      },
      {
        headerName: this.screenLabels["Active"]
          ? this.screenLabels["Active"]
          : "Active",
        field: "Active",
        hide: false,
        sortable: true,
        cellRenderer: (data) => {
          return data.value ? "Yes" : "No";
        },
      },
      {
        headerName: this.screenLabels["ModifiedBy"]
          ? this.screenLabels["ModifiedBy"]
          : "Modified By",
        field: "ModifiedBy",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["ModifiedDate"]
          ? this.screenLabels["ModifiedDate"]
          : "Modified Date",
        field: "ModifiedDate",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["CreatedBy"]
          ? this.screenLabels["CreatedBy"]
          : "Created By",
        field: "CreatedBy",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["CreatedDate"]
          ? this.screenLabels["CreatedDate"]
          : "Created Date",
        field: "CreatedDate",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["CreatedByName"]
          ? this.screenLabels["CreatedByName"]
          : "Created By Name",
        field: "CreatedByName",
        hide: true,
        sortable: true,
      },
      {
        headerName: this.screenLabels["ModifiedByName"]
          ? this.screenLabels["ModifiedByName"]
          : "Modified By Name",
        field: "ModifiedByName",
        hide: true,
        sortable: true,
      },
    ];
    this.rowSelection = "multiple";
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef()
  }

  // make ag-rid columns fill their container
  onFirstDataRendered(params) {
    setTimeout(() => {
      this.spinnerService.setIsLoading(false);
    }, 0);
    params.api.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridApi = params.api;
  }

  // sets the data of selected row
  onSelectionChanged($event) {
    const selectedRows = $event.api.getSelectedRows();
    this.selectedRow = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }
    if (selectedRows[0].Active) {
      this.isActive = true;
    } else {
      this.isActive = false;
    }
  }

  // invokes function when 'Assign / Edit Team' Button is clicked
  onAssignUpdateTeamButtonClicked($event) {
    if (this.gridApi.getSelectedRows().length) {
      const dialogRef = this.dialog.open(AssignUpdateTeamModalComponent, {
        disableClose: true,
        data: this.gridApi.getSelectedRows()[0],
      });
    }
  }

  // invokes function when 'Delete User' Button is clicked
  onDeleteUser() {
    if (this.gridApi.getSelectedRows().length) {
      const body = this.gridApi.getSelectedRows()[0];
      body.Active = 0;
      body.ModifiedBy = this.userService.userID;
      body.UserID = this.userService.userID;
      this.dialogService
        .openConfirmDialog(
          this.screenLabels["DeleteUserConfirmLabel"] ? this.screenLabels["DeleteUserConfirmLabel"] : "Confirm User Delete",
          this.screenLabels["DeleteUserConfirmMsg"] ? this.screenLabels["DeleteUserConfirmMsg"] : "Are you sure you want to delete the user ?"
        )
        .afterClosed()
        .subscribe((data: boolean) => {
          if (data) {
            this.userService
              .updateUserAssociationsAPI(body)
              .then(() => {
                this.notificationService.success(this.userService.message);
                this.userService.userDataChanged.emit(true);
              })
              .catch((err: HttpErrorResponse) =>
                this.notificationService.failure(err.message)
              );
          }
        });
    }
  }

  // invokes function when 'Enable User' Button is clicked
  onEnableUser() {
    if (this.gridApi.getSelectedRows().length) {
      const body = this.gridApi.getSelectedRows()[0];
      body.Active = 1;
      body.ModifiedBy = this.userService.userID;
      body.UserID = this.userService.userID;
      this.dialogService
        .openConfirmDialog(
          this.screenLabels["EnableUserConfirmLabel"] ? this.screenLabels["EnableUserConfirmLabel"] : "Confirm User Enable",
          this.screenLabels["InviteUser"] ? this.screenLabels["InviteUser"] : "Are you sure you want to enable the user ?"
        )
        .afterClosed()
        .subscribe((data: boolean) => {
          if (data) {
            this.userService
              .updateUserAssociationsAPI(body)
              .then(() => {
                this.notificationService.success(this.userService.message);
                this.userService.userDataChanged.emit(true);
              })
              .catch((err: HttpErrorResponse) =>
                this.notificationService.failure(err.message)
              );
          }
        });
    }
  }

  //on organization dropdown change
  onOrganizationChange($event) {
    var data = $event;

    // log organization change
    this.organizationService.logOrganizationChange(
      this.searchService.getOrganizationID(),
      data.value
    );

    this.searchService.setOrganizationID(data.value);

    this.userService.organizationID = data.value;
    this.userService.userOrganizations.forEach((element) => {
      if (element.OrganizationID == this.userService.organizationID)
        this.userService.IsAdmin = element.IsAdmin
          ? element.IsAdmin == 1
            ? 1
            : 0
          : 0;
    });
    this.orgAdminOrganizations = [];
    this.selectedOrganizationID = this.userService.organizationID;
    this.userService.userOrganizations.forEach((element) => {
      if (element.IsAdmin == 1) {
        this.orgAdminOrganizations.push(element);
      }
    });
    this.userService.organizationChanged.emit(true);
    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.rowData = [];
        this.rowData = this.searchService.searchRecords;
        this.rowData = this.rowData.filter(row => row && row.userID !== null);
        this.gridApi.paginationGoToPage(0);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  // the method is invoked when invite user button is clicked
  protected onInviteUser() {
    const dialogRef = this.dialog.open(InviteNewUserModal, {
      disableClose: true,
    });
  }

  //on remove user
  protected onUnlinkUser() {
    if (this.gridApi.getSelectedRows().length) {
      const body = this.gridApi.getSelectedRows()[0];
      let selectedData = this.gridApi.getSelectedRows().map(ele => ele.UserID)

      this.dialogService
        .openConfirmDialog(
          this.screenLabels["DeleteOrgAccessConfirmLabel"] ? this.screenLabels["DeleteOrgAccessConfirmLabel"] : "Confirm Organization Access Removal",
          this.screenLabels["DeleteOrgAccessConfirmMsg"] ? this.screenLabels["DeleteOrgAccessConfirmMsg"] : "Are you sure you want to remove the user/users from organization ?"
        )
        .afterClosed()
        .subscribe((data: boolean) => {
          if (data) {
            this.userService
              .unlinkUserAPI(selectedData)
              .then(() => {
                this.notificationService.success(this.userService.message);
                this.userService.userDataChanged.emit(true);
              })
              .catch((err: HttpErrorResponse) =>
                this.notificationService.failure(err.message)
              );
          }
        });
    }
  }
}

import { Injectable, Output, EventEmitter, ComponentFactoryResolver } from "@angular/core";
import { clone, cloneDeep } from "lodash";
import { environment } from "src/environments/environment";
import { UserService } from "./user.service";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { UserActionsService } from "./user-actions.service";
import {
  ISystemSettingsGetAPIResponse,
  ISiteConfig
} from "../models/interfaces/system-settings-get-api.model";
import { SpinnerService } from "./spinner.service";
import { NotificationService } from "./notification.service";
import { M } from "@angular/cdk/keycodes";
import { Router } from "@angular/router";
import { ifStmt } from "@angular/compiler/src/output/output_ast";
import moment from "moment";

export interface Site {
  SiteID: number;
  Name: string;
  LogoPath: string;
  SiteTimeZone: string;
  IsExternal: number;
  UserStatus: string;
}

@Injectable({
  providedIn: "root",
})
export class SiteService {
  @Output() siteChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _sites: Array<Site> = [];
  public availableSiteList: any;

  private _selectedSiteID: number;
  private _defaultSiteID: number;
  private _siteUTCOffset: string;
  _siteSettings: ISystemSettingsGetAPIResponse;


  private _dataTimeFormat: {
    dateFormat: string;
    timeFormat: string;

    angularDateFormat: string;
    angularTimeFormat: string;
    momentTimeFormat: string;
    igxTimeFormat: string;
  } = {
      dateFormat: null,
      timeFormat: null,

      angularDateFormat: null,
      angularTimeFormat: null,
      momentTimeFormat: null,
      igxTimeFormat: null,
    };

  public _siteHasParking: boolean = false;
  public _siteTemporaryBadgeEnabled: boolean = false;
  public _siteCallInPassEnabled: boolean = false;
  public _sitePhotoEnabled: boolean = false;
  public _siteCameraEnabled: boolean = false;
  public _siteHasCountry: boolean = false;

  public message: string;
  //siteSettings: ISystemSettingsGetAPIResponse;

  get siteUTCOffset() {
    return this._siteUTCOffset;
  }

  set siteUTCOffset(offset: string) {
    this._siteUTCOffset = offset;
  }

  get siteSettings() {
    return cloneDeep(this._siteSettings);
  }

  get dataTimeFormat() {
    return this._dataTimeFormat;
  }

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private userActionsService: UserActionsService,
    private spinnerService: SpinnerService,
    private notificationService: NotificationService
  ) {
    // this._sites.push({ SiteID: 1, Name: "Burbank", LogoPath: "" });
    // this._sites.push({ SiteID: 2, Name: "Seattle", LogoPath: "" });
    // this._sites.push({ SiteID: 3, Name: "Leavesden", LogoPath: "" });

    this.siteChanged.subscribe((sc) => {
      // log it into user action
      const oldSiteName = this._defaultSiteID
        ? this.getSiteByID(this._defaultSiteID).Name
        : null;
      const newSiteName = this._selectedSiteID
        ? this.getSiteByID(this._selectedSiteID).Name
        : null;

      this.userActionsService.recordUserActionAPI(
        "Site Change",
        `Changed From: ${oldSiteName} (${this._defaultSiteID}) To: ${newSiteName} (${this._selectedSiteID})`
      );
      this._defaultSiteID = this._selectedSiteID;
    });
  }

  // method to store sites
  public initSites(sites: Array<Site>) {
    this._sites = cloneDeep(sites);
  }

  // the method returns all the sites
  public getSites(): Site[] {
    return clone(this._sites);
  }

  get selectedSiteID(): number {
    return this._selectedSiteID;
  }

  set selectedSiteID(siteID: number) {
    this._selectedSiteID = siteID;
  }

  get defaultSiteID(): number {
    return this._defaultSiteID;
  }

  set defaultSiteID(siteID: number) {
    this._defaultSiteID = siteID;
  }

  // method to extract site fro the site id
  public getSiteByID(siteID: number): Site {
    return cloneDeep(this._sites.find((s) => s.SiteID === siteID));
  }

  // method is invoked to get the selected site
  public getSelectedSite(): Site {
    return this.getSiteByID(this._selectedSiteID);
  }

  //method is invoked to get available Site Names

  public availableSiteNames(): Array<String> {
    let siteNames: Array<String> = [];
    this._sites.forEach((element) => {
      siteNames.push(element.Name);
    });
    return siteNames;
  }

  // the method should be invoked to set default site for a given user
  public setDefaultSiteAPI(siteID: number, userID?: number) {
    const apiURL = environment.getAPI("setUserDefaultSite");
    const body = {
      SiteID: siteID,
      UserID: this.userService.userID ? this.userService.userID : userID,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    this.spinnerService.setIsLoading(true);

    const promise = new Promise((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          async (success: HttpResponse<any>) => {
            // success
            this.message = success.body.Message;
            this.spinnerService.setIsLoading(false);

            this.notificationService.success(this.message);
            // console.log(success.body);
            resolve(this.message);
          },
          (err) => {
            // error
            this.spinnerService.setIsLoading(false);
            this.notificationService.failure(err);
            console.error(err);

            reject(err);
          }
        );
    });
    return promise;
  }

  public getSiteSettingsAPI() {
    const apiURL = environment.getAPI("getSystemSettings");

    const body = {
      SiteID: this.selectedSiteID,
    };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<ISystemSettingsGetAPIResponse>(
      (resolve, reject) => {
        this.http
          .post(apiURL, body, httpOptions)
          .toPromise()
          .then(
            async (success: HttpResponse<any>) => {
              // success
              this._siteSettings = success.body;
              this._setSiteDateTimeFormat();
              this._setSiteParkingEnabled();
              this._setSiteTemporaryBadgeEnabled();
              this._setCallInPassEnabled();
              this._setCheckinPhotoEnabled();
              this._setCameraEquipmentEnabled()
              //  this._setCountryEnabled();
              resolve(success.body);
            },
            (err) => {
              // error
              reject(err);
            }
          );
      }
    );
    return promise;
  }

  public createUpdateSiteSettingsAPI(siteSettings: ISiteConfig[]) {
    const apiURL = environment.getAPI("setSystemSettings");

    const body = { SystemSettings: siteSettings };
    const httpOptions: { headers; observe } = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      observe: "response",
    };

    const promise = new Promise<void>((resolve, reject) => {
      this.http
        .post(apiURL, body, httpOptions)
        .toPromise()
        .then(
          async (success: HttpResponse<any>) => {
            // success

            // console.log(success.body);
            resolve(success.body.Message);
          },
          (err) => {
            // error
            reject(err);
          }
        );
    });
    return promise;
  }

  public async initializeSiteSettings() {
    await this.getSiteSettingsAPI();
    this._setSiteDateTimeFormat();
    this._setSiteParkingEnabled();
    this._setSiteTemporaryBadgeEnabled();
    this._setCallInPassEnabled();
    this._setCheckinPhotoEnabled();
    this._setCameraEquipmentEnabled()
    this._setCountryEnabled();

  }

  private _setSiteDateTimeFormat() {
    let angularDateFormatString: string;
    let angularTimeFormatString: string;
    let momentTimeFormatString: string;
    let igxTimeFormat: string;
    const dateFormatSetting = this._siteSettings.SystemSettings.find(
      (ss) =>
        ss.Name === "DateFormat" && ss.Category === "Date" && ss.Active === 1
    );
    const timeFormatSetting = this._siteSettings.SystemSettings.find(
      (ss) =>
        ss.Name === "TimeFormat" && ss.Category === "Time" && ss.Active === 1
    );

    switch (dateFormatSetting.Value.toLowerCase()) {
      case "dd/mm/yyyy":
        // angularDateFormatString = "dd/MM/yyyy";
        angularDateFormatString = "dd/MM/yyyy";
        break;
      case "mm/dd/yyyy":
        angularDateFormatString = "MM/dd/yyyy";
        break;
      case "yyyy/mm/dd":
        angularDateFormatString = "yyyy/MM/dd";

        break;
    }

    switch (timeFormatSetting.Value.toLowerCase()) {
      case "12 hours":
        angularTimeFormatString = "h:mm a";
        momentTimeFormatString = "h:mm A";
        igxTimeFormat = "hh:mm tt";
        break;
      case "24 hours":
        angularTimeFormatString = "HH:mm";
        momentTimeFormatString = "HH:mm";
        igxTimeFormat = "HH:mm";
        break;
    }

    this._dataTimeFormat.angularDateFormat = angularDateFormatString;
    this._dataTimeFormat.angularTimeFormat = angularTimeFormatString;
    this._dataTimeFormat.momentTimeFormat = momentTimeFormatString;
    this._dataTimeFormat.igxTimeFormat = igxTimeFormat;

    this._dataTimeFormat.dateFormat = dateFormatSetting.Value;
    this._dataTimeFormat.timeFormat = timeFormatSetting.Value;

  }

  private _setSiteParkingEnabled() {
    const parkingAvailability = this._siteSettings.SystemSettings.find(
      (ss) =>
        ss.Name === "ParkingEnabled" && ss.Category === "Parking" && ss.Active === 1
    );
    this._siteHasParking = parkingAvailability.Value == '1' ? true : false
  }

  private _setSiteTemporaryBadgeEnabled() {
    const temporaryBadgeEnabled = this._siteSettings.SystemSettings.find(
      (ss) =>
        ss.Name === "TemporaryBadgeEnabled" && ss.Category === "Badge" && ss.Active === 1
    );
    this._siteTemporaryBadgeEnabled = temporaryBadgeEnabled.Value == '1' ? true : false;
  }

  private _setCallInPassEnabled() {


    const callInPassEnabled = this._siteSettings.SystemSettings.find(
      (ss) =>
        ss.Name === "CallInPassEnabled" && ss.Category === "CallInPass" && ss.Active === 1
    );
    this._siteCallInPassEnabled = callInPassEnabled ? (callInPassEnabled.Value) == '1' ? true : false : true;
  }

  private _setCheckinPhotoEnabled() {
    const photoEnabled = this._siteSettings.SystemSettings.find(
      (ss) =>
        ss.Name === "PassTransaction" && ss.Category === "CheckInPhoto" && ss.Active === 1
    );
    this._sitePhotoEnabled = photoEnabled.Value == '1' ? true : false;
  }

  private _setCameraEquipmentEnabled() {
    const CameraEnabled = this._siteSettings.SystemSettings.find(
      (ss) =>
        ss.Name === "Pass" && ss.Category === "CameraEquipment" && ss.Active === 1
    );
    this._siteCameraEnabled = CameraEnabled.Value == '1' ? true : false;
  }

  private _setCountryEnabled() {
    const countryEnable = this._siteSettings.SystemSettings.find(
      (ss) =>
        ss.Name === "CountryEnabled" && ss.Category === "Country" && ss.Active === 1
    );
    this._siteHasCountry = countryEnable.Value == '1' ? true : false
  }
  /**
   * The method checks if a given site is under maintenance or not.
   * @returns if the site is under maintenance
   */
  public isWIPSite() {
    const wipSiteNames = ['CNN - LA'];
    const selectedSiteName = this.getSites().find(s => this.selectedSiteID === s.SiteID).Name;
    const isWIP = wipSiteNames.find(w => w.toLowerCase() === selectedSiteName.toLowerCase()) ? true : false;
    localStorage["loggedInSiteName"] = selectedSiteName;
    return isWIP;
  }

}

import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material";
import { HttpErrorResponse } from "@angular/common/http";
import { AuthService } from "src/app/services/auth.service";
import { MatExpansionPanel } from "@angular/material";
import { GridApi } from "ag-grid-community";
import { SearchService } from "src/app/services/search.service";
import { UserService } from "src/app/services/user.service";
import { TemplateCategoryService } from "src/app/services/template-category-service";
import { NotificationService } from "src/app/services/notification.service";
import { TemplateCategory } from "src/app/models/template-category,model";
import { CreateTemplateCategoryComponent } from "./create-template-category-modal/create-template-category-modal.component";
import { UpdateTemplateCategoryComponent } from "./edit-template-category-modal/edit-template-category-modal.component";
import { DialogService } from "src/app/services/dialog.service";
import { ViewTemplateCategoryComponent } from "./view-template-category-modal/view-template-category-modal.component";
import { OrganizationService } from "src/app/services/organization.service";
import { SiteService } from "src/app/services/site.service";
import { MessageService } from "src/app/services/message.service";

@Component({
  selector: "app-template-category-modal",
  templateUrl: "./template-category-modal.component.html",
  styleUrls: ["./template-category-modal.component.css"],
})
export class TemplateCategoryComponent implements OnInit {
  @ViewChild("freePanel") freezePanel: MatExpansionPanel;

  private gridApi: GridApi;

  protected isRowSelected: boolean = false;
  protected selectedRequest = null;
  protected selectedTemplateCategory: TemplateCategory;
  protected gridOptions: Object;
  protected rowData: Array<any> = [];

  protected step = 0;
  protected isActive: boolean = false;
  public orgAdminOrganizations: Array<{
    OrganizationID: number;
    Name: string;
    StartDate: string;
    EndDate: string;
    IsAdmin: number;
  }> = [];
  protected selectedOrganizationID: number;
  protected appLanguage;
  protected columnDefs : any ;
  public screenLabels: Array<string> = [];
  public defaultColDef : object;

  constructor(
    protected dialog: MatDialog,
    protected authService: AuthService,
    protected searchService: SearchService,
    protected userService: UserService,
    protected templateCategoryService: TemplateCategoryService,
    protected notificationService: NotificationService,
    protected dialogService: DialogService,
    private organizationService: OrganizationService,
    protected siteService:SiteService,
    protected messageService: MessageService,
  ) {
    this.gridOptions = {
      getRowClass: (params) =>{
        if(params.data.Active === 0){
          return 'row-inactive';
        }
      }
    }
    this.defaultColDef = {
      resizable: true,
  }
  }

  ngOnInit(): void {
    this.searchService.setPageName("Template Category");
//labels by message service
if (localStorage.getItem('TemplateCategoryLabels')) {
  this.appLanguage = JSON.parse(localStorage.getItem('TemplateCategoryLabels'));
  this.loadTranslatedLabels();
} else {
  this.messageService.getLabelLanguageObs(
    "Template Category",
      "TemplateCategory"
  ).subscribe((res: any) => {
    this.appLanguage = res.body;
    localStorage.setItem('TemplateCategoryLabels', JSON.stringify(this.appLanguage));
    this.loadTranslatedLabels();
  })
}

    this.selectedOrganizationID = this.userService.organizationID;
    this.userService.userOrganizations.forEach((element) => {
      if (element.IsAdmin == 1) {
        this.orgAdminOrganizations.push(element);
      }
    });
    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.rowData = this.searchService.searchRecords;
        this.rowData = this.rowData.filter(
          (f) => f.OrganizationID == this.userService.organizationID
        );
        this.gridApi.paginationGoToPage(0);
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    // new search initiated
    this.searchService.searchInitiated.subscribe(() => {
      this.searchService.smartSearchPromise
        .then(() => {
          this.isRowSelected = false;
          this.rowData = this.searchService.searchRecords;
          this.rowData = this.rowData.filter(
            (f) => f.OrganizationID == this.userService.organizationID
          );
          this.gridApi.paginationGoToPage(0);
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    });

    // subscribe to site changed
    this.siteService.siteChanged.subscribe((event: boolean) => {
      if(event){
        this.searchService
        .smartSearchMultiSiteAPI(null)
        .then(() => {
          this.isRowSelected = false;
          this.rowData = this.searchService.searchRecords;
          this.rowData = this.rowData.filter(
            (f) => f.OrganizationID == this.userService.organizationID
          );         
          this.gridApi.paginationGoToPage(0);  
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
      }
    });

    //  Template Category  data changed
    this.templateCategoryService.templateCategoryDataChanged.subscribe(
      (data: boolean) => {
        this.isRowSelected = false;
        this.searchService
          .smartSearchMultiSiteAPI(null)
          .then(() => {
            this.rowData = this.searchService.searchRecords;
            this.rowData = this.rowData.filter(
              (f) => f.OrganizationID == this.userService.organizationID
            );
            this.gridApi.paginationGoToPage(0);
          })
          .catch((err: HttpErrorResponse) => {
            this.notificationService.failure(err.message);
          });
      }
    );
  }

  setColumnDef(){
    this.columnDefs = [
      {
        headerName: "",
        field: "",
        width: 1,
        cellStyle: { color: "#589bf8" },
        hide: false,
        checkboxSelection: true,
        resizable: false,
        sortable: false,
        lockPosition: true,
      },
  
      {
        headerName: this.screenLabels["CategoryNameAgGrid"]
            ? this.screenLabels["CategoryNameAgGrid"]
            : "Category Name",
        field: "Category",
        sortable: true,
        filter: true,
        hide: false,
      },
      {
        headerName:this.screenLabels["TemplateCategoryID"]
        ? this.screenLabels["TemplateCategoryID"]
        : "Template Category ID",
        field: "TemplateCategoryID",
        sortable: true,
        filter: true,
        hide: true,
      },
      {
        headerName:this.screenLabels["UserIDs"]
        ? this.screenLabels["UserIDs"]
        : "Template Users",
        field: "UserIDs",
        sortable: true,
        filter: true,
        hide: true,
      },
      {
        headerName:this.screenLabels["Active"]
        ? this.screenLabels["Active"]
        : "Active",
        field: "Active",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["ModifiedBy"]
        ? this.screenLabels["ModifiedBy"]
        : "Modified By",
        field: "ModifiedBy",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["ModifiedDate"]
        ? this.screenLabels["ModifiedDate"]
        : "ModifiedDate",
        field: "ModifiedDate",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["CreatedBy"]
        ? this.screenLabels["CreatedBy"]
        : "CreatedBy",
        field: "CreatedBy",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["CreatedDate"]
        ? this.screenLabels["CreatedDate"]
        : "CreatedDate",
        field: "CreatedDate",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["CreatedByName"]
        ? this.screenLabels["CreatedByName"]
        : "Created By Name",
        field: "CreatedByName",
        hide: true,
        sortable: true,
      },
      {
        headerName:this.screenLabels["ModifiedByName"]
        ? this.screenLabels["ModifiedByName"]
        : "Modified By Name",
        field: "ModifiedByName",
        hide: true,
        sortable: true,
      },
    ];
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate}));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
    this.setColumnDef()
}

  // invoked when ag-grid is initialized
  protected onGridReady(params): void {
    this.gridApi = params.api;
  }

  // make ag-rid columns fill their container
  protected onFirstDataRendered(params) {
    params.api.sizeColumnsToFit();
  }

  onMainHeaderClose() {
    this.freezePanel.open();
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  onCreateTemplatePass() {
    // Create Template pass
    const dialogRef = this.dialog.open(CreateTemplateCategoryComponent, {
      disableClose: true,
      data: [],
      backdropClass: "backdropBackground",
    });
  }

  // sets the data of selected row
  onSelectionChanged($event) {
    const selectedRows: TemplateCategory[] = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.selectedTemplateCategory = selectedRows[0];
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }
    if (this.selectedTemplateCategory.Active === 0) {
      this.isActive = false;
    } else {
      this.isActive = true;
    }
  }

  //on organization dropdown change
  onOrganizationChange($event) {
    var data = $event;

    // log organization change
    this.organizationService.logOrganizationChange(
      this.searchService.getOrganizationID(),
      data.value
    );

    this.searchService.setOrganizationID(data.value);

    this.userService.organizationID = data.value;
    this.userService.userOrganizations.forEach((element) => {
      if (element.OrganizationID == this.userService.organizationID)
        this.userService.IsAdmin = element.IsAdmin
          ? element.IsAdmin == 1
            ? 1
            : 0
          : 0;
    });
    this.orgAdminOrganizations = [];
    this.selectedOrganizationID = this.userService.organizationID;
    this.userService.userOrganizations.forEach((element) => {
      if (element.IsAdmin == 1) {
        this.orgAdminOrganizations.push(element);
      }
    });
    this.userService.organizationChanged.emit(true);
    this.searchService.setPageName("Template Category");
    this.searchService
      .smartSearchMultiSiteAPI(null)
      .then(() => {
        this.rowData = [];
        this.rowData = this.searchService.searchRecords;
        this.rowData = this.rowData.filter(
          (f) => f.OrganizationID == this.userService.organizationID
        );
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  // Invoked when Edit Template Category
  onEditTemplate($event) {
    if (this.selectedTemplateCategory) {
      this.dialog.open(UpdateTemplateCategoryComponent, {
        disableClose: true,
        data: this.selectedTemplateCategory,
      });
    }
  }

  // Invoked when Edit Template Category
  openTemplateCategory($event) {
    var selectedTemplateCategory: TemplateCategory = $event.data;
    this.dialog.open(ViewTemplateCategoryComponent, {
      disableClose: true,
      data: selectedTemplateCategory,
    });
  }

  // Delete Template Category
  DeleteTemplateCategory() {
    var message: string =
      "Are you sure you want to Delete Template Category  ?";
    this.dialogService
      .openConfirmDialog(this.screenLabels["DeleteTemplateCategoryBtn"]?
      this.screenLabels["DeleteTemplateCategoryBtn"]: "Delete Template Category", message)
      .afterClosed()
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.templateCategoryService
            .deleteTemplateCategoryAPI(this.selectedTemplateCategory)
            .then(() => {
              this.notificationService.success(
                this.screenLabels["TemplateCategoryDeleteSuccessMsg"]?
      this.screenLabels["TemplateCategoryDeleteSuccessMsg"]:"Template Category was Deleted Successfully."
              );
            });
        }
      });
  }
}

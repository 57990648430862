import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  FormControl,
  Validators,
  NgForm,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PassService } from "../services/pass.service";
import { BuildingService } from "../services/building.service";
import { UserService } from "../services/user.service";
import { DatePipe } from "@angular/common";
import { NotificationService } from "../services/notification.service";
import { EntryPointService } from "../services/entry-point.service";
import {
  MatRadioChange,
  MatSelectChange,
  MatCheckboxChange,
  MatChipInputEvent,
  DateAdapter,
  MAT_DATE_FORMATS,
  MatStepper,
} from "@angular/material";
import { DialogService } from "../services/dialog.service";
import { EntryPoint } from "../models/entry-point.model";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { EntryPointGroupService } from "../services/entry-point-group.service";
import { EntryPointGroup } from "../models/entry-point-group.model";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { IValidationError } from "../newpass-modal/desktop/newpass-modal.component.desktop";
import {
  DataValidator,
  ValidationErrorCodes,
} from "../common/utils/data-validation";
import { OrganizationService } from "../services/organization.service";
import { ParkingLotService } from "../services/parking-lot.service";
import { RequiredFieldsService } from "../services/required-fields.service";
import { SearchService } from "../services/search.service";
import { SEMICOLON } from "@angular/cdk/keycodes";
import moment from "moment";
import { default as _rollupMoment } from "moment";
import { SiteService } from "../services/site.service";
import { UtilityService } from "../services/utility.service";
import {
  MatDatePickerAdapter,
  CUSTOM_DATE_FORMATS,
} from "../common/mat-date-picker-formatter/mat-date-picker-formatter";
import { SpinnerService } from '../services/spinner.service';
import { ISystemSettingsGetAPIResponse } from '../models/interfaces/system-settings-get-api.model';
import { HolidayService } from "../services/holiday-calendar.service";
import { BasicSnackbarComponent } from "../snack-bar/basic-snackbar/basic-snackbar.component";
import { MessageService } from "../services/message.service";
import { IgxTimePickerComponent } from "igniteui-angular";

export interface DialogData { }
export interface Reason {
  value: string;
  viewValue: string;
}
export interface Point {
  name: string;
}
export interface PointGrps {
  name: string;
}
export interface Building {
  name: string;
}
export interface iAPIBody {
  PassID: number;
  destination: any;
  entryPoint: any;
  VisitorFirstName: string;
  VisitorMiddleName: string;
  VisitorLastName: string;
  VisitorNameSuffix: string;
  VisitorCompany: string;
  VisitorEmail: string;
  VisitorPhone: string;
  VisitorCountry: number;
  StartTime: string;
  EndTime: string;
  EstArrivalTime: string;
  EstDepartureTime: string;
  VisitReason: number;
  IncludeNonWorkingdays: boolean;
  ExpectedEntryPoint: number;
  EntryPointGroupID: number;
  EntryType: number;
  NotesInternal: string;
  NotesPass: string;
  ParkingLotID: number;
  ParkingSpot: string;
  DestinationBldg: number;
  DestinationBlgFlr: number;
  DestinationRoom: string;
  HostFirstName: string;
  HostMiddleName: string;
  HostLastName: string;
  HostNameSuffix: string;
  HostCompany: string;
  HostPhone: string;
  HostEmail: string;
  HostTWID: string;
  OrganizationID: number;
  Authorizer: number;
  Operator: number;
  PhotoPass: string;
  Active: boolean;
  CopyFromPassID: string;
  PartyID: number;
  EmployeeRef: number;
  UserID: number;
}
export interface Visitor {
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  email: string;
  phone: string;
  country: string;
}

@Component({
  selector: "app-duplicate-pass-modal",
  templateUrl: "./desktop/duplicate-pass-modal.component.desktop.html",
  styleUrls: ["./desktop/duplicate-pass-modal.component.desktop.css"],
  providers: [
    { provide: DateAdapter, useClass: MatDatePickerAdapter },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
})
export abstract class DuplicatePassModalComponent
  implements OnInit, AfterViewInit {
  @ViewChild("copyPassForm") copyPassForm: NgForm;
  @ViewChild("expirationDate") expirationDate: ElementRef;
  @ViewChild("startDate") startDate: ElementRef;
  @ViewChild("endDate") endDate: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('estArrivalTime', { read: IgxTimePickerComponent, static: true })
  public estArrivalTime: IgxTimePickerComponent;
  @ViewChild('estDepartureTime', { read: IgxTimePickerComponent, static: true })
  public estDepartureTime: IgxTimePickerComponent;

  buildingControl = new FormControl();

  filteredOptions: Observable<string[]>;

  protected ModifiedData;
  private apiBody: any = {};
  protected addnlVisitor: Visitor[] = [];
  protected entryPoints: Array<EntryPoint> = [];
  protected entryPointGroups: Array<EntryPointGroup> = [];
  protected isReasonOfVisitLoading = true;
  protected isBuildingLoading = true;
  protected isEntryPointLoading = true;
  protected isParkingDisabled = true;
  protected isExpirationDateDisabled = true;
  protected orgStartDate: string;
  protected orgEndDate: string;
  protected minDate = this.utilService.getCurrentSiteDT();
  protected maxDate = new Date(
    this.utilService.getCurrentSiteDT().setDate(this.minDate.getDate() + 14)
  );
  protected minDuration = this.minDate;
  protected maxDuration;
  protected parkingCrossedBy: number = 0;
  protected DriveOnGuestPass: number = 0;
  protected entryPointCrossedBy: number = 0;
  protected parkingLimit: number = 0;
  protected entryPointZoneLimit: number;
  protected allZonesSelfGoverened: boolean = false;
  private attemptedValidation: boolean = false;

  protected selectedStartDate: any;
  protected selectedEndDate: any;
  protected parkingLotName: string;
  protected parkingLotID: number;
  public remainingParkingCount: number;
  public remainingParking: Array<{
    OrganizationID: number;
    OrganizationName: string;
    OrganizationLimit: number;
    ParkingLotGroupID: number;
    ParkingLotGroupName: string;
    ParkingLotID: number;
    ParkingLotName: string;
    AllocatedParkingCount: number;
    BalanceParkingCount: number;
  }> = [];

  // row validation class rules
  protected rowClassRules;

  // Party Threshold Limit
  private PARTY_LIMIT = 12;

  // for the ag grid
  protected columnDefs = [];
  protected validationErrors: Array<IValidationError> = [];
  protected gridApi;
  private file: File;
  private arrayBuffer: any;
  protected isMultiplePassSelected: boolean = false;
  protected isFileUploadTypeSelected: boolean = false;

  protected uniquePasses = [];
  protected EntryType: number;
  protected DriveOnGuestPasses: number = 0;

  private guestPasses: Array<{
    VisitorFirstName: string;
    VisitorMiddleName: string;
    VisitorLastName: string;
    VisitorNameSuffix: string;
    VisitorCompany: string;
    VisitorEmail: string;
    VisitorPhone: string;
    VisitorCountry: number;
    EntryType: number;
  }> = [];
  protected rowData = [];
  protected assignedParking: Array<{
    ParkingLotName: string;
    ParkingLotID: number;
  }> = [];
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [SEMICOLON];
  private siteResponse: ISystemSettingsGetAPIResponse;
  protected hostEmails: Array<{
    email: String;
    valid: String;
  }> = [];
  public onBackspaceKeydown(event) {
    event.stopImmediatePropagation();
  }
  createPass: FormGroup;
  submitted = false;
  emailPattern = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  authorizerNameFormControl = new FormControl("", [Validators.required]);
  authcodeFormControl = new FormControl("", [Validators.required]);
  phoneFormControl = new FormControl("", [Validators.required]);
  vstrFirstNameFormControl = new FormControl("", [Validators.required]);
  vstrLastNameFormControl = new FormControl("", [Validators.required]);
  adnlvstrFirstNameFormControl = new FormControl("", [Validators.required]);
  adnlvstrLastNameFormControl = new FormControl("", [Validators.required]);
  adnlvstrMiddleNameFormControl = new FormControl("", [Validators.required]);
  adnlvstrSuffixFormControl = new FormControl("", [Validators.required]);
  hostFirstNameControl = new FormControl("", Validators.required);
  hostLastNameControl = new FormControl("", Validators.required);
  activationDate = new FormControl("", Validators.required);
  expirationDateControl = new FormControl("", Validators.required);
  departureTimeControl = new FormControl("", Validators.required);
  arrivalTimeControl = new FormControl("", Validators.required);
  //entryPointGroupFormControl = new FormControl("", Validators.required);
  visitorLastNameControl = new FormControl("", Validators.required);
  visitorFirstNameControl = new FormControl("", Validators.required);
  emailFormControl = new FormControl("", [
    Validators.required,
    Validators.email,
    Validators.pattern(this.emailPattern),
  ]);

  protected entryPoinIndicator: boolean = false;
  public isInvalid: Boolean = false;
  public errorMessage: string;
  private chosenBuildingId;
  public buildingName: string = "";
  public entryPointGroupName: string = "";
  public buildingNames: string[];
  public buildingList;
  protected enableNext: boolean = false;
  protected displayStartDate;
  protected displayEndDate;
  public minTime: string = '';
  public validEntryPointGroup: boolean = false;

  protected holidays: any[] = [];
  protected checkedDates: boolean = false;
  protected checkDatesNext: boolean = false;
  protected checkDatesError: boolean = false;
  protected startElement: boolean = false;
  protected endElement: boolean = false;
  protected dayRangeArray: any[] = [];
  protected workingDays: any[] = [];

  protected finalPassTmpData: any;
  protected duplicateTempPassID;
  protected partyPassFlag: Boolean = false;
  protected activateSpinner: boolean = false;
  gridOptions: any;
  public killCount: number = 0;
  public UserTaskID: number;
  protected enableSubmit: Boolean = true;
  protected floors: any[] = [];
  protected phoneIsUSA: boolean = false;
  protected capacityMessage;
  protected appLanguage;
  public screenLabels: Array<string> = [];
  protected countryList: any;
  private countries = {};
  protected isRowSelected: boolean = false;
  protected stepperCall: boolean = false;
  protected reqArrivalTime: boolean = false;
  protected reqDepartureTime: boolean = false;
  public defaultColDef : object;
  protected emailValidation: boolean = false;
  protected Getfloors: any[] = [];
  protected buildingArr: any[] = [];
  protected partyID: number;
  private isIgnoreLocks: boolean = false;
  isValidEmail(email: string): boolean {
    const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/
    return emailPattern.test(email);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: iAPIBody,
    public dialogRef: MatDialogRef<DuplicatePassModalComponent>,
    public passService: PassService,
    protected buildingService: BuildingService,
    protected userService: UserService,
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    protected entryPointService: EntryPointService,
    protected dialogService: DialogService,
    private entryPointGroupService: EntryPointGroupService,
    private organizationService: OrganizationService,
    protected parkingLotService: ParkingLotService,
    protected searchService: SearchService,
    protected requiredFieldsService: RequiredFieldsService,
    protected siteService: SiteService,
    private utilService: UtilityService,
    private spinnerService: SpinnerService,
    protected holidayService: HolidayService,
    private _snackBar: MatSnackBar,
    private messageService: MessageService
  ) {
    this.gridOptions = {
      singleClickEdit: true,
      enableCellChangeFlash : true,
      onCellKeyDown: this.onCellKeyDown
    }
    this.ModifiedData = { ...data };
    var arDate = moment(this.data.StartTime).format("YYYY-MM-DD h:mm:ss");
    var depDate = moment(this.data.EndTime).format("YYYY-MM-DD h:mm:ss");
    this.ModifiedData.StartTime = new Date(arDate);
    this.ModifiedData.EndTime = new Date(depDate);
    this.isParkingDisabled = this.ModifiedData.EntryType == 1 ? false : true; // enable ParkingDrodown if EntryType is Drive on
    //Api call to get ParkingLotDropdown
    this.organizationService
      .getOrganizationByIDAPI(this.data.OrganizationID)
      .then(() => {
        const details = this.organizationService.getOrganizationByIDResponse()[0];
        details.OrganizationParkingLimitArray.map((p) => {
          if (p.ParkingLotArray) {
            p.ParkingLotArray.map((array) => {
              if (array) {
                this.assignedParking.push(array);
              }
            });
          }
        });
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });

    // Load Entry Point Groups
    if (this.entryPointGroupService.getEntryPointGroups().length > 0) {
      // No API call is required
      this.entryPointGroups = this.entryPointGroupService
        .getEntryPointGroups()
        .filter((epg: any) => epg.Active === 1);
      this.entryPoints = this.entryPointGroupService.getGroupMappings(
        this.data.EntryPointGroupID
      ).EntryPoints;
      this.validEntryPointGroup = this.entryPointGroups.some(item => item.EntryPointGroupID == this.ModifiedData.EntryPointGroupID);
    } else {
      // API call is required
      this.entryPointGroupService
        .getAllEntryPointGroupsAPI(this.data.OrganizationID)
        .then(() => {
          this.entryPointGroups = this.entryPointGroupService.getEntryPointGroups();
          this.entryPoints = this.entryPointGroupService.getGroupMappings(
            this.data.EntryPointGroupID
          ).EntryPoints;
          this.validEntryPointGroup = this.entryPointGroups.some(item => item.EntryPointGroupID == this.ModifiedData.EntryPointGroupID);
        })
        .catch((err: HttpErrorResponse) =>
          this.notificationService.failure(err.message)
        );
    }

    // Format Arrival and Departure Dates
    var arDate = moment(this.data.StartTime).format("YYYY-MM-DD h:mm:ss");
    var depDate = moment(this.data.EndTime).format("YYYY-MM-DD h:mm:ss");
    this.ModifiedData.StartTime = new Date(arDate);
    this.ModifiedData.EndTime = new Date(depDate);
    // Format Arrival and Departure Times
    var estAr = moment(this.data.EstArrivalTime).format("YYYY-MM-DD h:mm A");
    var estDep = moment(this.data.EstDepartureTime).format("YYYY-MM-DD h:mm A");
    this.ModifiedData.EstArrivalTime = new Date(estAr);
    this.ModifiedData.EstDepartureTime = new Date(estDep);
    // set Start Time (Activation Date) to '' if start date is less than the current date
    this.userService
      .getUserAssociationAPI(
        this.userService.userID,
        this.siteService.selectedSiteID
      )
      .then(() => this.initializeDates());

    // set row styles
    this.rowClassRules = {
      "row-ignore": (params) => {
        var condition = params.data.IgnoreDuplicate === true;
        return condition;
      },
      "row-warning": function (params) {
        var condition =
          params.data.IsADuplicate === true &&
          params.data.IgnoreDuplicate != true;
        return condition;
      },
      stopEditingWhenCellsLoseFocus: true,
    };
    this.defaultColDef = {
      resizable: true,
  }
  }
  passData: any;
  durationInSeconds = 3;
  step = 0;
  disabled = false;

  private fieldArray: Array<any> = [];
  public newAttribute: any = {};

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  nextStepValid(startDate, endDate, entryPointGroupId) {
   // this.gridApi.setColumnDefs(this.initColDef());
    const groupNames: any[] = this.entryPointGroupService.getEntryPointGroups();
    this.entryPointGroupName = groupNames.find((element: any) => element.EntryPointGroupID === entryPointGroupId).EntryPointGrpName;
    const capacity = this.screenLabels["CapacityUtilizationMsg"];
    this.capacityMessage = capacity.replace("<<id1>>", this.entryPointGroupName).replace("<<id2>>", moment(startDate).format("MM/DD/YYYY")).replace("<<id3>>", moment(endDate).format("MM/DD/YYYY"));
    this.stepperCall = true;
    this.setEntryLimit(
      startDate,
      endDate,
      this.userService.organizationID,
      entryPointGroupId
    );

    this.checkDatesOnNext();

    if (this.ModifiedData.IncludeNonWorkingdays) {
      if (this.checkDatesNext) {
        this.checkDatesError = false;
        this.entryPoinIndicator = false;
      }
    }
    else if (!this.ModifiedData.IncludeNonWorkingdays) {
      if (!this.checkDatesNext) {
        this.checkDatesError = false;
        // this.entryPoinIndicator = false;
      }
      else if (this.checkDatesNext) {
        this.checkDatesError = true;
        this.enableNext = true;
      }
    }
  }

  prevStep() {
    this.step--;
  }
  nextSteptwo() {
    this.email.markAsTouched();
    if (!this.email.invalid) {
      this.step++;
    }
  }
  email = new FormControl("", [Validators.required, Validators.email]);
  //email validation
  getErrorMessage() {
    if (this.email.hasError("required")) {
      return this.screenLabels["ValueEnterMsg"] ? this.screenLabels["ValueEnterMsg"] : "You must enter a value";
    }

    return this.email.hasError("email") ? this.screenLabels["NotValidEmailMsg"] ? this.screenLabels["NotValidEmailMsg"] : "Not a valid email" : "";
  }

  //Building autocomplete
  myControl = new FormControl();

  //Entry type autocomplete
  entryControl = new FormControl();
  entryOptions: Point[] = [
    { name: "1658" },
    { name: "153" },
    { name: "8080" },
    { name: "16616" },
  ];
  filteredPoints: Observable<Point[]>;
  entrygrpControl = new FormControl();
  entrygrpOptions: PointGrps[] = [
    { name: "A" },
    { name: "B" },
    { name: "C" },
    { name: "D" },
  ];
  filteredPointGrps: Observable<PointGrps[]>;

  ngOnInit() {
    this.buildingService.getFloors(this.ModifiedData.DestinationBldg).subscribe((res) => {
      this.Getfloors = res.body;
    });
    if (localStorage.getItem("isPhoneUSA") === "true") {
      this.phoneIsUSA = true;
    }
    else if (localStorage.getItem("isPhoneUSA") === "false") {
      this.phoneIsUSA = false;
    }
    
    //condition for the host information to be disable for guards
    //It should be enabled for Site Admin, Org Admins, people who can ignore locks
    //Site Admin and Org Admins, by default ignore lock should be 1
    const userOrg = this.userService.getUserDetails();
    let selectedPassOrgID = userOrg[0].UserOrganization.filter(e => e.Name === this.ModifiedData.OrganizationName).map(ele => ele.OrganizationID);
    let isOrgAdmin = 0;
    isOrgAdmin = userOrg[0].UserOrganization.filter(e => e.Name === this.ModifiedData.OrganizationName).map(ele => ele.IsAdmin);

    if(this.userService.organizationID !== selectedPassOrgID[0]) {
      this.passService.checkIsIgnoreLocksAPI(this.userService.userID, selectedPassOrgID[0],false).subscribe((res: HttpResponse<any>) => {
        this.passService.ignoreLocks = res.body.IsIgnoreLocks;
        if (this.passService.ignoreLocks == 1) this.isIgnoreLocks = true;
        if(isOrgAdmin[0] === 1) this.isIgnoreLocks = true;
      });
    }
    else {
      if(isOrgAdmin[0] === 1) this.isIgnoreLocks = true;
      if (this.passService.ignoreLocks == 1) this.isIgnoreLocks = true;  
    }

    // If the cancel is being initiated in the multipass screen
    this.passService.cancelInitiated.subscribe((e) => {
      this.onNoClick();
    });

    this.holidayService.getHolidaysYearAPI().subscribe(response => {
      response.HolidayDates.forEach(element => {
        let formatDate = moment(element).format("YYYY-MM-DD");
        this.holidays.push(formatDate);
      })
      response.NonworkingDates.forEach(element => {
        let nonworking = moment(element).format("YYYY-MM-DD");
        //let formatted = new Date(nonworking).getTime();
        this.holidays.push(nonworking);
      })
    }, error => {
      console.log(error);
    });

    this.passService.getVisitReasonsAPI().then(() => {
      this.isReasonOfVisitLoading = false;
    });
    if (this.ModifiedData.DestinationBldg > 0) {
      var buildings = this.buildingService.getBuildings();
      this.chosenBuildingId = this.ModifiedData.DestinationBldg;
      this.buildingName = buildings.filter(
        (f) => f.BuildingID == this.ModifiedData.DestinationBldg
      )[0].BuildingName;
    } else {
      var buildings = this.buildingService.getBuildings();
      this.buildingName = buildings[0].BuildingName;
    }

     this.buildingArr = this.buildingService.getBuildings();
    this.buildingList = this.buildingArr;
    this.buildingNames = this.buildingArr.map(function (item) {
      return item["BuildingName"];
    });
    this.filteredOptions = this.buildingControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
    if (this.buildingList.length === 1) {
      this.buildingName = this.buildingList[0].BuildingName;
      this.chosenBuildingId = this.buildingList[0].BuildingID;
    }

    //Initialise Site Settings  getSiteSettingsAPI
    if (this.ModifiedData.VisitReason === null) {
      this.siteResponse = this.siteService.siteSettings;
      if (this.siteResponse) {
        const defaultReason = this.siteResponse.SystemSettings ? (this.siteResponse.SystemSettings.find(x => x.Name === "DefaultVisitReason")) : null;
        this.ModifiedData.VisitReason = Number(defaultReason.Value);
      }
    }

    //labels by message service
    if (localStorage.getItem('DuplicatePassLabels')) {
      this.appLanguage = JSON.parse(localStorage.getItem('DuplicatePassLabels'));
      this.loadTranslatedLabels();
    } else {
      this.messageService.getLabelLanguageObs(
        "Duplicate Pass Modal",
        "Pass"
      ).subscribe((res: any) => {
        this.appLanguage = res.body;
        localStorage.setItem('DuplicatePassLabels', JSON.stringify(this.appLanguage));
        this.loadTranslatedLabels();
      })
    }

    if (this.siteService._siteHasCountry) {
      this.passService.getCountryListAPI().subscribe((res: HttpResponse<any>) => {
        this.countryList = res.body;
        this.countryList.forEach((element) => {
          this.countries[element.PickListID] = element.DisplayValue;
        });
      }, error => {
        console.log(error);
      });
    }

    var hostEmails: String[] = (this.data.HostEmail) ? this.data.HostEmail.split(";") : null;
    if (this.data.HostEmail) {
      hostEmails.forEach((element) => {
        this.hostEmails.push({ email: element, valid: "valid" });
      });
    }

    if (this.ModifiedData.ParkingLotID) {
      this.setParkingLimit(
        this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
        this.datePipe.transform(this.selectedEndDate, "yyyy-MM-dd"),
        this.userService.organizationID
      );
    }

    this.buildingService.parseAPI().then(() => {
      this.isBuildingLoading = false;
    });

    this.isEntryPointLoading = false;

    //typeAheads
    this.myControl.setValue({ name: this.data.destination });
    this.entryControl.setValue({ name: this.data.entryPoint });

    this.filteredPoints = this.entryControl.valueChanges.pipe(
      startWith(""),
      map((value) => (typeof value === "string" ? value : value.name)),
      map((name) =>
        name ? this._filterPoint(name) : this.entryOptions.slice()
      )
    );
    this.filteredPointGrps = this.entrygrpControl.valueChanges.pipe(
      startWith(""),
      map((value) => (typeof value === "string" ? value : value.name)),
      map((name) =>
        name ? this._filterPointGrp(name) : this.entrygrpOptions.slice()
      )
    );

    // Commented this code as it was throwing error
    // if (this.siteService._siteHasParking) {
    //   this.copyPassForm.value.entryType = 2;
    // }

    // fill additional guests

    this.passService
      .getPartyPassesAPI(this.data.PassID)
      .then(() => {
        const additionalGuests = this.passService.getPassesfromSameParty(
          this.data.PartyID,
          this.data.PassID
        );

        if (additionalGuests) {
          additionalGuests.forEach((ag) => {
            this.rowData.push({
              VisitorFirstName: ag.VisitorFirstName,
              // VisitorMiddleName: ag.VisitorMiddleName,
              VisitorMiddleName: "",
              VisitorLastName: ag.VisitorLastName,
              VisitorNameSuffix: ag.VisitorNameSuffix,
              VisitorEmail: ag.VisitorEmail,
              VisitorPhone: ag.VisitorPhone,
              VisitorCompany: ag.VisitorCompany,
              VisitorCountry: ag.VisitorCountry,
            });
          });
        }

        this.isMultiplePassSelected =
          (this.data.PartyID != null || undefined) &&
          additionalGuests.length > 1;
        if (this.isMultiplePassSelected) {
          this.guestPasses = this.rowData;
          if (this.ModifiedData.EntryPointGroupID > 0) {
            this.stepperCall = false;
            this.setEntryLimit(
              this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
              this.datePipe.transform(this.selectedEndDate, "yyyy-MM-dd"),
              this.userService.organizationID,
              this.ModifiedData.EntryPointGroupID
            );
          }
          if (this.EntryType == 1 && this.ModifiedData.ParkingLotID > 0) {
            this.setParkingLimit(
              this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
              this.datePipe.transform(this.selectedEndDate, "yyyy-MM-dd"),
              this.userService.organizationID
            );
          }
        }
      })
      .catch((err: HttpErrorResponse) =>
        this.notificationService.failure(err.message)
      );

    //initialize minTime for timePicker based on arrival time

    if (this.ModifiedData.EstArrivalTime) {
      if (this.siteService.dataTimeFormat.angularTimeFormat == '12 hours') {
        this.minTime = moment(this.ModifiedData.EstArrivalTime).format(
          "hh:mm tt"
        );
      } else {
        this.minTime = moment(this.ModifiedData.EstArrivalTime).format(
          "HH:mm"
        );
      }
    }
  }

  stepChanged(event, stepper) {
    stepper.selected.interacted = false;
    if (event.selectedIndex == 2){
      this.gridApi.setColumnDefs(this.initColDef());
    }
  }

  loadTranslatedLabels() {
    const labels = this.appLanguage.map((o) => ({ FieldName: o.FieldName, Label: o.MessageTemplate }));
    this.screenLabels = [];
    labels.forEach((l) => (this.screenLabels[l.FieldName] = l.Label));
  }

  onInputChange () {
    if (this.buildingName == "") {
      this.ModifiedData.DestinationBlgFlr = null;
      this.Getfloors  = [];
    }
  }

  //start code for building dropdown selection //
  private setTimeoutRef: any = null;
  onChangeBuilding(event: string, isBlur?: boolean) {

    if (this.buildingName == "") {
      this.ModifiedData.DestinationBlgFlr = null;
      this.ModifiedData.DestinationBldg = null;
      this.floors.length = null;
    }

    if (this.buildingName && this.ModifiedData.DestinationBlgFlr) {
      this.ModifiedData.DestinationBldg = null;
      this.ModifiedData.DestinationBlgFlr = null;
    }

    // Find the building in buildingArr that matches the provided event (building name)
    const matchingBuilding = this.buildingArr.find(
      (building) => building.BuildingName === event
    );

    if (matchingBuilding) {
      // Extract the necessary information from the matching building
      this.chosenBuildingId = matchingBuilding.BuildingID;
    }
    if (this.buildingName) {
      // this is an asynchronous call, subscribe to getFloors
      this.buildingService.getFloors(this.chosenBuildingId).subscribe((res) => {
        this.Getfloors = res.body;
        if(this.Getfloors.length < 1) {
          this.enableNext = false;
        } else {
          this.enableNext = true;
        }
      });
    }

    // const buildingNames: any[] = this.buildingService.getBuildings();
    // const selectedBuild: any = buildingNames.find((element: any) => element.BuildingName === event);
    // if (selectedBuild) {
    //   if (this.setTimeoutRef) {
    //     clearTimeout(this.setTimeoutRef);
    //     this.setTimeoutRef = null;
    //   }
    //   this.buildingName = selectedBuild.BuildingName;
    //   this.ModifiedData.DestinationBldg = selectedBuild.BuildingID;
    //   this.chosenBuildingId = selectedBuild.BuildingID;
    //   this.buildingService.parseAPI().then(() => {
    //     var buildingArr = this.buildingService.getBuildings();
    //     this.buildingList = buildingArr;
    //     this.buildingNames = buildingArr.map(function (item) {
    //       return item["BuildingName"];
    //     });
    //     this.filteredOptions = this.buildingControl.valueChanges.pipe(
    //       startWith(""),
    //       map((value) => this._filter(value))
    //     );
    //   });
    // } else if (isBlur) {
    //   this.setTimeoutRef = setTimeout(() => {
    //     this.buildingName = "";
    //     this.buildingControl.setValue("");
    //     this.setTimeoutRef = null;
    //   }, 500);
    // }
    // if (this.buildingName) {
      // this.floors = this.buildingService.getBuildingFloors(this.chosenBuildingId ? +this.chosenBuildingId : null);
      // if (this.floors.length >= 1) {
      //   this.enableNext = true;
      // }
      // else if (this.floors.length == 0) {
      //   this.enableNext = false;
      // }

      // this.buildingService.getFloors(this.chosenBuildingId).subscribe((res)=>{
      //   this.Getfloors = res.body
      // })

    // }
  }

  onFloor(event) {
    if (this.Getfloors.length >= 1 && this.ModifiedData.DestinationBlgFlr) {
      this.enableNext = false;
    }
    else if (this.Getfloors.length >= 1 && !this.ModifiedData.DestinationBlgFlr) {
      this.enableNext = true;
    }
  }

  ngAfterViewChecked() {
    // setting up the required fields
    this.requiredFieldsService.setRequiredFields(
      'Copy Pass',
      this.copyPassForm
    );
    this.timeValidation();
  }

  ngAfterViewInit() {
    this.columnDefs = this.initColDef();
  }

  //building type ahead
  displayFn(building?: Building): string | undefined {
    return building ? building.name : undefined;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.utilService.searchSortKeyUtil(this.buildingNames, value);
  }

  onParkingLotChange() {
    this.setParkingLimit(
      this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
      this.datePipe.transform(this.selectedEndDate, "yyyy-MM-dd"),
      this.userService.organizationID
    );
    if (this.ModifiedData.ParkingLotID) {
      this.enableNext = false;
    }
    else if (!this.ModifiedData.ParkingLotID) {
      this.enableNext = true;
    }
  }

  //entry point type ahead
  displayPoints(point?: Point): string | undefined {
    return point ? point.name : undefined;
  }

  private _filterPoint(name: string): Point[] {
    const filterValue = name.toLowerCase();

    return this.entryOptions.filter(
      (entryOption) => entryOption.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private _filterPointGrp(name: string): PointGrps[] {
    const filterValue = name.toLowerCase();

    return this.entrygrpOptions.filter(
      (entryOption) => entryOption.name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  onEntryTypeChange(event: MatRadioChange) {
    if (event.value == 1) {
      if (!this.ModifiedData.ParkingLotID) {
        this.enableNext = true;
      } else {
        this.enableNext = false;
      }
      this.isParkingDisabled = false;
    } else {
      this.enableNext = false;
      this.isParkingDisabled = true;
      this.ModifiedData.ParkingLotID = "" ;
    }
    if (this.ModifiedData.ParkingLotID > 0) {
      this.setParkingLimit(
        this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
        this.datePipe.transform(this.selectedEndDate, "yyyy-MM-dd"),
        this.userService.organizationID
      );
    }
  }



  onNoClick() {
    this.dialogRef.close(false);
    this.passService.duplicatePassOpen = false;
  }

  addFieldValue() {
    if (true) {
      this.addnlVisitor.push(this.newAttribute);
      this.newAttribute = {};
    }
  }

  deleteFieldValue(index) {
    this.addnlVisitor.splice(index, 1);
  }

  onCopyPass() {
    let promise: Promise<any>;
    //this.apiBody.Active = true;

    this.apiBody.Authorizer = this.userService.userID;
    this.apiBody.VisitorFirstName = !this.copyPassForm.value.visitorFirstName
      ? null
      : this.copyPassForm.value.visitorFirstName;
    this.apiBody.VisitorLastName = !this.copyPassForm.value.visitorLastName
      ? null
      : this.copyPassForm.value.visitorLastName;
    // this.apiBody.VisitorMiddleName = !this.copyPassForm.value.visitorMiddleName
    //   ? null
    //   : this.copyPassForm.value.visitorMiddleName;
    this.apiBody.VisitorMiddleName = "";
    this.apiBody.VisitorNameSuffix = !this.copyPassForm.value.visitorNameSuffix
      ? null
      : this.copyPassForm.value.visitorNameSuffix;
    this.apiBody.VisitorCompany = !this.copyPassForm.value.visitorCompany
      ? null
      : this.copyPassForm.value.visitorCompany;
    this.apiBody.VisitorEmail = !this.copyPassForm.value.visitorEmail
      ? null
      : this.copyPassForm.value.visitorEmail;
    this.apiBody.VisitorPhone = !this.copyPassForm.value.visitorPhone
      ? null
      : this.copyPassForm.value.visitorPhone;
    this.apiBody.VisitorCountry = !this.copyPassForm.value.visitorCountry
      ? null
      : this.copyPassForm.value.visitorCountry;
    this.apiBody.StartTime = !this.copyPassForm.value.startTime
      ? null
      : this.datePipe.transform(
        this.copyPassForm.value.startTime,
        "yyyy-MM-dd"
      );
    this.apiBody.EndTime = !this.copyPassForm.value.endTime
      ? null
      : this.datePipe.transform(this.copyPassForm.value.endTime, "yyyy-MM-dd");

    let ArrivalDate = moment(this.copyPassForm.value.startTime).format("YYYY-MM-DD");
    let ArrivalTime = moment(this.copyPassForm.value.estArrivalTime).format("HH:mm:ss");
    let ArrivalTimeDate = moment(ArrivalDate + ' ' + ArrivalTime);

    this.apiBody.EstArrivalTime = !this.copyPassForm.value.estArrivalTime
      ? null
      : this.formatDate(ArrivalTimeDate);

    let DepatureDate = moment(this.copyPassForm.value.endTime).format("YYYY-MM-DD");
    let DepatureTime = moment(this.copyPassForm.value.estDepartureTime).format("HH:mm:ss");
    let DepatureTimeDate = moment(DepatureDate + ' ' + DepatureTime);

    this.apiBody.EstDepartureTime = !this.copyPassForm.value.estDepartureTime
      ? null
      : this.formatDate(DepatureTimeDate);

    this.apiBody.VisitReason = !this.copyPassForm.value.visitReason
      ? null
      : +this.copyPassForm.value.visitReason;
    this.apiBody.IncludeNonWorkingdays = !this.copyPassForm.value
      .includeNonWorkingdays
      ? null
      : this.copyPassForm.value.includeNonWorkingdays;
    this.apiBody.ExpectedEntryPoint = !this.copyPassForm.value
      .expectedEntryPoint
      ? null
      : +this.copyPassForm.value.expectedEntryPoint;
    this.apiBody.EntryPointGroupID = this.ModifiedData.EntryPointGroupID;
    this.apiBody.ParkingSpot = this.ModifiedData.ParkingSpot;
    this.apiBody.EntryType = !this.copyPassForm.value.entryType
      ? null
      : this.copyPassForm.value.entryType;
    this.apiBody.NotesInternal = !this.copyPassForm.value.notesInternal
      ? null
      : this.copyPassForm.value.notesInternal;
    this.apiBody.NotesPass = !this.copyPassForm.value.notesPass
      ? null
      : this.copyPassForm.value.notesPass;
    this.apiBody.ParkingLotID =
      this.apiBody.EntryType == 1 ? +this.ModifiedData.ParkingLotID : null;
    this.apiBody.DestinationBldg = !this.chosenBuildingId
      ? null
      : +this.chosenBuildingId;
    this.apiBody.DestinationBlgFlr = this.ModifiedData.DestinationBlgFlr ? this.ModifiedData.DestinationBlgFlr : null;
    this.apiBody.DestinationRoom = !this.copyPassForm.value.destinationBlgRoom
      ? null
      : this.copyPassForm.value.destinationBlgRoom;
    this.apiBody.HostFirstName = this.disabled
      ? this.userService.loggedInUserFirstName
      : !this.ModifiedData.HostFirstName
        ? null
        : this.ModifiedData.HostFirstName;
   // this.apiBody.HostMiddleName = this.copyPassForm.value.isHostAndAuthorizerSame ? this.userService.loggedInUserMiddleName: !this.copyPassForm.value.hostMiddleName ? null: this.copyPassForm.value.hostMiddleName;
   this.apiBody.HostMiddleName = "";
    this.apiBody.HostLastName = this.disabled
      ? this.userService.loggedInUserLastName
      : !this.ModifiedData.HostLastName
        ? null
        : this.ModifiedData.HostLastName;
    this.apiBody.HostNameSuffix = !this.ModifiedData.HostNameSuffix ? null : this.ModifiedData.HostNameSuffix;
    this.apiBody.HostCompany = !this.ModifiedData.HostCompany
      ? null
      : this.ModifiedData.HostCompany;
    this.apiBody.HostPhone = !this.ModifiedData.HostPhone
      ? null
      : this.ModifiedData.HostPhone;
    var hostEmails: String[] = [];
    this.hostEmails.forEach((element) => {
      if (element.valid === "valid") {
        hostEmails.push(element.email);
      }
    });
    this.apiBody.HostEmail = this.disabled
      ? this.userService.loggedInEmail
      : this.hostEmails.length > 0
        ? hostEmails.join(";")
        : null;
    this.apiBody.HostTWID = null;
    this.apiBody.OrganizationID = this.userService.organizationID;
    this.apiBody.Authorizer = this.userService.userID;
    this.apiBody.Operator =
      this.userService.loggedInUserID > 0
        ? this.userService.loggedInUserID
        : this.userService.userID;
    this.apiBody.PhotoPass = this.ModifiedData.PhotoPass ? 1 : 0;
    this.apiBody.Active = true;
    this.apiBody.CopyFromPassID = this.ModifiedData.PassID;
    this.apiBody.EmployeeRef = null;
    this.apiBody.UserID = this.userService.userID;
    this.apiBody.AllowDup = false;
    this.apiBody.TemplateID = 0;
    this.apiBody.VisitorEmail = !this.copyPassForm.value.visitorEmail
      ? null
      : this.copyPassForm.value.visitorEmail;

    if (this.apiBody.EntryPointGroupID) {
      this.organizationService
        .getEntryPointZoneLimitAnalyticsAPI(
          this.selectedStartDate,
          this.selectedEndDate,
          this.apiBody.OrganizationID,
          null,
          this.ModifiedData.EntryPointGroupID,
          this.ModifiedData.IncludeNonWorkingdays,
          this.siteService.selectedSiteID
        )
        .then(() => {
          const entryPointGrpDetails = this.organizationService.entryPointZoneLimitAPIResponse
          if (
            (entryPointGrpDetails.length > 0 &&
              entryPointGrpDetails[0].FreeSpace <= 0 &&
              !entryPointGrpDetails[0].regulated) ||
            this.entryPointCrossedBy > 0
          ) {
            const entryPointZone = this.screenLabels["EntryPointZone"] ? this.screenLabels["EntryPointZone"] : "Entry Point Zone";
            const entryPointZoneFilledMsg = this.screenLabels["EntryPointZoneFilledMsg"] ? this.screenLabels["EntryPointZoneFilledMsg"] : "Entry Point Zone Filled for selected Entry Point Group"
            this.dialogService.openAlertDialogDialog(
              entryPointZone,
              entryPointZoneFilledMsg
            );
          } else if (this.apiBody.ParkingLotID) {
            this.organizationService
              .getParkingLimitAnalyticsAPI(
                this.selectedStartDate,
                this.selectedEndDate,
                this.userService.organizationID,
                null
              )
              .then(() => {
                let balanceCount: number = 0;
                this.remainingParking = this.organizationService.parkingLimitAPIResponse.filter(
                  (f) =>
                    f.OrganizationID == this.userService.organizationID &&
                    f.ParkingLotID == this.apiBody.ParkingLotID
                );
                if (
                  (this.remainingParking.length <= 0 ||
                    this.remainingParkingCount <= 0) &&
                  this.parkingCrossedBy > 0
                ) {
                  this.remainingParkingCount = this.remainingParking[0].BalanceParkingCount;
                }
                if (
                  this.remainingParking.length <= 0 ||
                  this.remainingParkingCount <= 0
                ) {
                  if (this.parkingLotService.getParkingLot().length > 0) {
                    this.parkingLotName = this.parkingLotService
                      .getParkingLot()
                      .filter(
                        (p) => p.ParkingLotID === this.apiBody.ParkingLotID
                      )[0].Name;
                  } else {
                    this.parkingLotService.getParkingLotAPI().then(() => {
                      this.parkingLotName = this.parkingLotService
                        .getParkingLot()
                        .filter(
                          (p) => p.ParkingLotID === this.apiBody.ParkingLotID
                        )[0].Name;
                    });
                  }
                  const parkingLot = this.screenLabels["ParkingLot"] ? this.screenLabels["ParkingLot"] : "Parking Lot";
                  const parkingFilledMsg = (this.screenLabels["ParkingFilledMsg"]).replace("<<id>>", this.parkingLotName);
                  this.dialogService.openAlertDialogDialog(
                    parkingLot,
                    parkingFilledMsg
                  );
                  return;
                } else {
                  if (!this.isMultiplePassSelected) {
                    // Logic to create individual passes
                    this.createIndividualPass(this.apiBody);
                    this.onNoClick();
                  }
                }
              })
              .catch((err: HttpErrorResponse) => {
                this.notificationService.failure(err.message);
              });
          }
          else {
            if (!this.isMultiplePassSelected) {
              // Logic to create individual passes
              this.createIndividualPass(this.apiBody);
              this.onNoClick();
            }
          }
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationService.failure(err.message);
        });
    } else if (this.apiBody.ParkingLotID) {
      this.setParkingLimit(
        this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
        this.datePipe.transform(this.selectedEndDate, "yyyy-MM-dd"),
        this.userService.organizationID
      );
    } else {
      if (!this.isMultiplePassSelected) {
        // Logic to create individual passes
        this.createIndividualPass(this.apiBody);
        this.onNoClick();
      }
    }
  }

  formatDate(data: any) {
    let date = this.utilService.stripLocalTimeZone(data.toString());
    return data ? date : null;
  }

  onEntryPointGroupChange(event: MatSelectChange) {

    //For the limit funtionality
    if (this.entryPoinIndicator) {
      this.enableNext = false;
      this.entryPoinIndicator = false;
    }
    const entryPointGroupID = event.value;
    this.ModifiedData.ExpectedEntryPoint = 0;
    this.entryPoints = this.entryPointGroupService.getGroupMappings(
      entryPointGroupID
    ).EntryPoints;
    this.validEntryPointGroup = this.entryPointGroups.some(item => item.EntryPointGroupID == this.ModifiedData.EntryPointGroupID);
    this.OnIncludeNonWorkingDays();
    if (this.checkDatesError) {
      this.enableNext = false;
      this.checkDatesError = false;
    }
  }
  startDateChanged(event) {
    this.checkDateValidation();
    //for the limit funtionality 
    if (this.entryPoinIndicator) {
      this.enableNext = false;
      this.entryPoinIndicator = false;
    }

    //date type event.value
    const tempDate = new Date(event.value).getDate();
    if (this.datePipe.transform(new Date(event.value), "yyyy-MM-dd") < this.datePipe.transform(new Date(this.minDate), "yyyy-MM-dd")) {
      this.ModifiedData.StartTime = undefined;
      this.expirationDate.nativeElement.value = null;
      this.isExpirationDateDisabled = false;
    }
    else
      if (this.datePipe.transform(new Date(event.value), "yyyy-MM-dd") > this.datePipe.transform(new Date(this.maxDate), "yyyy-MM-dd")) {
        this.ModifiedData.StartTime = undefined;
        this.expirationDate.nativeElement.value = null;
        this.isExpirationDateDisabled = false;
      } else {
        this.minDuration = new Date(event.value);
        this.maxDuration =
          new Date(this.orgEndDate) <
            new Date(new Date(event.value).setDate(tempDate + 30))
            ? new Date(this.orgEndDate)
            : new Date(new Date(event.value).setDate(tempDate + 30));

        this.expirationDate.nativeElement.value = null;
        this.isExpirationDateDisabled = false;
        this.selectedStartDate = this.minDuration;
        this.selectedEndDate = this.maxDuration;
        this.entryPointCrossedBy = 0;
        // Set entry Point Limit based on the date change
        //Set Parking Limit based on date change if parking lot is selected
        if (
          this.ModifiedData.ParkingLotID != undefined &&
          this.ModifiedData.ParkingLotID > 0
        ) {
          this.setParkingLimit(
            this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
            this.datePipe.transform(this.selectedEndDate, "yyyy-MM-dd"),
            this.userService.organizationID
          );
        }
      }
    this.OnIncludeNonWorkingDays();
    if (this.checkDatesError) {
      this.enableNext = false;
      this.checkDatesError = false;
    }
    this.startElement = false;
    this.endElement = false;
    this.dayRangeArray = [];
  }
  endDateChanged(event) {
    this.checkDateValidation();
    //For the limit functionality
    if (this.entryPoinIndicator) {
      this.enableNext = false;
      this.entryPoinIndicator = false;
    }
    const endDate = new Date(event.value);
    if (this.datePipe.transform(new Date(event.value), "yyyy-MM-dd") < this.datePipe.transform(new Date(this.minDuration), "yyyy-MM-dd")) {
      this.ModifiedData.EndTime = undefined;
    } else
      if (this.datePipe.transform(new Date(event.value), "yyyy-MM-dd") > this.datePipe.transform(new Date(this.maxDuration), "yyyy-MM-dd")) {
        this.ModifiedData.EndTime = undefined;
      }
      else {
        this.selectedEndDate = endDate;
        //Set Parking Limit based on date change if parking lot is selected
        if (
          this.ModifiedData.ParkingLotID != undefined &&
          this.ModifiedData.ParkingLotID > 0
        ) {
          this.setParkingLimit(
            this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
            this.datePipe.transform(this.selectedEndDate, "yyyy-MM-dd"),
            this.userService.organizationID
          );
        }
      }

    this.OnIncludeNonWorkingDays();
    if (this.checkDatesError) {
      this.enableNext = false;
      this.checkDatesError = false;
    }
    this.startElement = false;
    this.endElement = false;
    this.dayRangeArray = [];
  }

  //set Parking limit and parking crossed by
  setParkingLimit(startDate, endDate, organizationID) {
    this.parkingCrossedBy = 0;
    this.DriveOnGuestPass = 0;
    this.guestPasses.forEach((pass) => {
      if (pass.EntryType == 1) {
        ++this.DriveOnGuestPass;
      }
    });
    this.organizationService
      .getParkingLimitAnalyticsAPI(startDate, endDate, organizationID, null)
      .then(() => {
        this.remainingParking = this.organizationService.parkingLimitAPIResponse.filter(
          (f) =>
            f.OrganizationID == this.userService.organizationID &&
            f.ParkingLotID == this.ModifiedData.ParkingLotID
        );
        if (
          this.remainingParking != undefined ||
          this.remainingParking.length > 0
        ) {
          this.parkingLimit = this.remainingParking[0].BalanceParkingCount;
          if (this.parkingLimit < this.DriveOnGuestPass) {
            this.parkingCrossedBy = this.DriveOnGuestPass - this.parkingLimit;
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationService.failure(err.message);
      });
  }

  //set Entry limit and Entry crossed by
  setEntryLimit(startDate, endDate, organizationID, entryPointGroupID) {
    this.spinnerService.setIsLoading(true);
    if(this.stepperCall) {
      this.stepper.selectedIndex = this.stepper.selectedIndex - 1;
    }
    //this.stepper.selectedIndex = this.stepper.selectedIndex - 1;
    this.displayStartDate = moment(startDate).format("MM/DD/YYYY");
    this.displayEndDate = moment(endDate).format("MM/DD/YYYY");
    this.organizationService
      .getEntryPointZoneLimitAnalyticsAPI(
        startDate,
        endDate,
        organizationID,
        null,
        entryPointGroupID,
        this.ModifiedData.IncludeNonWorkingdays,
        this.siteService.selectedSiteID
      )
      .then(() => {
        this.spinnerService.setIsLoading(false);
        const entryPointGrpDetails = this.organizationService.entryPointZoneLimitAPIResponse;
        this.entryPointCrossedBy = 0;
        this.entryPointZoneLimit = 0;
        this.allZonesSelfGoverened = false;
        this.entryPoinIndicator = false;
        if (entryPointGrpDetails[0].regulated) {
          this.allZonesSelfGoverened = true;
          // this.step++;
          if(this.stepperCall) {
          this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
          }
        } else {
          this.entryPointZoneLimit = entryPointGrpDetails[0].FreeSpace;
          if (
            this.guestPasses.length > 0 &&
            this.entryPointZoneLimit < this.guestPasses.length
          ) {
            this.entryPointCrossedBy =
              this.guestPasses.length - this.entryPointZoneLimit;
            if (this.entryPointCrossedBy > 0) {
              this.enableSubmit = true;
            }
          } else {
            //For the single pass creation
            if (this.entryPointZoneLimit <= 0) {
              this.entryPoinIndicator = true;
              this.enableNext = true;
              //on holiday msg...hiding entry limit msg
              if (this.checkDatesError) {
                this.entryPoinIndicator = false;
              }
            } else {
              // this.step++;
              if(this.stepperCall) {
              this.stepper.selectedIndex = this.stepper.selectedIndex + 1;
              }
            }
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.spinnerService.setIsLoading(false);
        this.notificationService.failure(err.message);
        this.enableNext = true;
      });
  }

  setEntryLimitLastStep(startDate, endDate, organizationID, entryPointGroupId) {
    this.spinnerService.setIsLoading(true);
    this.displayStartDate = moment(startDate).format("MM/DD/YYYY");
    this.displayEndDate = moment(endDate).format("MM/DD/YYYY");
    this.organizationService
      .getEntryPointZoneLimitAnalyticsAPI(
        startDate,
        endDate,
        organizationID,
        null,
        entryPointGroupId,
        this.ModifiedData.IncludeNonWorkingdays,
        this.siteService.selectedSiteID
      )
      .then(() => {
        this.spinnerService.setIsLoading(false);
        const entryPointGrpDetails = this.organizationService.entryPointZoneLimitAPIResponse;
        this.entryPointCrossedBy = 0;
        this.entryPointZoneLimit = 0;
        this.allZonesSelfGoverened = false;
        this.entryPoinIndicator = false;
        if (entryPointGrpDetails[0].regulated) {
          this.allZonesSelfGoverened = true;
        }
        else {
          this.entryPointZoneLimit = entryPointGrpDetails[0].FreeSpace;
          if (
            this.guestPasses.length > 0 &&
            this.entryPointZoneLimit < this.guestPasses.length
          ) {
            this.entryPointCrossedBy = this.guestPasses.length - this.entryPointZoneLimit;
          } else {
            //For the single pass creation
            if (this.entryPointZoneLimit <= 0) {
              this.entryPoinIndicator = true;
              this.enableNext = true;
              //on holiday msg...hiding entry limit msg
              if (this.checkDatesError) {
                this.entryPoinIndicator = false;
              }
            } else {
            }
          }
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.spinnerService.setIsLoading(false);
        this.notificationService.failure(err.message);
        this.enableNext = true;
      })
  }

  initializeDates() {
    var d = this.utilService.getCurrentSiteDT();
    var year = d.getFullYear();
    var month = d.getMonth();
    var day = d.getDate();
    const userAssociations = this.userService.getUserDetails();

    const selectedOrg = userAssociations[0].UserOrganization.filter(
      (uo) => uo.OrganizationID === this.userService.organizationID
    );
    this.orgEndDate = selectedOrg[0].Internal
      ? new Date(year + 10, month, day)
      : selectedOrg[0].EndDate;
    this.orgStartDate = selectedOrg[0].Startdate;
    this.minDate =
      new Date(this.orgStartDate) > this.utilService.getCurrentSiteDT()
        ? new Date(this.orgStartDate)
        : this.utilService.getCurrentSiteDT();
    this.minDuration =
      this.minDate < new Date(this.ModifiedData.StartTime)
        ? new Date(this.ModifiedData.StartTime)
        : this.minDate;
    this.maxDate =
      new Date(this.orgEndDate) <
        new Date(new Date(this.minDate).setDate(this.minDate.getDate() + 14))
        ? new Date(this.orgEndDate)
        : new Date(new Date(this.minDate).setDate(this.minDate.getDate() + 14));
    this.maxDuration =
      new Date(this.orgEndDate) <
        new Date(new Date(this.minDate).setDate(this.minDate.getDate() + 30))
        ? new Date(this.orgEndDate)
        : new Date(new Date(this.minDate).setDate(this.minDate.getDate() + 30));

    if (
      new Date(this.ModifiedData.StartTime) <
      this.utilService.getCurrentSiteDT()
    ) {
      this.ModifiedData.StartTime = null;
      this.ModifiedData.EndTime = null;
    } else {
      var arDate = moment(this.ModifiedData.StartTime).format("YYYY-MM-DD h:mm:ss");
      const tempDate = new Date(arDate).getDate();
      this.minDuration = new Date(arDate);
      this.maxDuration =
        new Date(this.orgEndDate) <
          new Date(new Date(arDate).setDate(tempDate + 30))
          ? new Date(this.orgEndDate)
          : new Date(new Date(arDate).setDate(tempDate + 30));
      this.selectedStartDate = this.minDuration;
      this.selectedEndDate = this.maxDuration;
    }
  }

  // invoke to toggle the view for multi pass upload.
  onMultiplePassSelectionChanged(event: MatCheckboxChange) {
    this.isMultiplePassSelected = event.checked;
  }

  // invoke to toggle file upload.
  onUploadTypeChanged(event: MatCheckboxChange) {
    this.isFileUploadTypeSelected = event.checked;
  }

  // set the file
  protected incomingfile(event) {
    this.file = event.target.files[0];
    event.target.value = '' ;
    if (this.rowData.length) {
      const EraseExistingTableDataMsg = this.screenLabels["EraseExistingTableDataMsg"] ? this.screenLabels["EraseExistingTableDataMsg"] : "Erase Existing Table Data";
      const EraseExistingDataErrorMsg = this.screenLabels["EraseExistingDataErrorMsg"] ? this.screenLabels["EraseExistingDataErrorMsg"] : "This action would erase all existing data in the table. Do you want to proceed ?";
      this.dialogService
        .openConfirmDialog(
          EraseExistingTableDataMsg,
          EraseExistingDataErrorMsg
        )
        .afterClosed()
        .subscribe((confirm: boolean) => {
          if (confirm) this.upload();
        });
    } else {
      this.upload();
    }
  }

  // invoke when the upload button is clicked.
  protected upload() {
    this.enableSubmit = true;
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join("");
      const workbook = XLSX.read(bstr, { type: "binary" });
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];

      const json = XLSX.utils.sheet_to_json(worksheet, { raw: true });

      this.formatPassUpload(json);
      this.rowData = [];
      //for checking rowlimit
      if (this.guestPasses.length <= 1000) {
        this.rowData = this.guestPasses;
        this.tempPassesCheck(this.apiBody, this.guestPasses);
      } else {
        this.rowData = [];
        const rowLimitReached = this.screenLabels["RowLimitReached"] ? this.screenLabels["RowLimitReached"] : "Row Limit Reached";
        const maximumNumberOfRows = this.screenLabels["MaximumNumberOfRows"] ? this.screenLabels["MaximumNumberOfRows"] : "Maximum number of rows is 1000";
        this.dialogService.openAlertDialogDialog(
          rowLimitReached,
          maximumNumberOfRows,
          "400px",
          "150px"
        );
      }
      // if (this.templateID > 0) {
      if (this.rowData.length > 0) {
        this.rowData.forEach((element) => {
          if (this.ModifiedData.VisitorFirstNameLock)
            element.VisitorFirstName = this.ModifiedData.VisitorFirstName;
          // if (this.ModifiedData.VisitorMiddleNameLock)
          //   element.VisitorMiddleName = this.ModifiedData.VisitorMiddleName;
          if (this.ModifiedData.VisitorMiddleNameLock)
          element.VisitorMiddleName = "";
          if (this.ModifiedData.VisitorLastNameLock)
            element.VisitorLastName = this.ModifiedData.VisitorLastName;
          if (this.ModifiedData.VisitorCompanyLock)
            element.VisitorCompany = this.ModifiedData.VisitorCompany;
          if (this.ModifiedData.VisitorEmailLock)
            element.VisitorEmail = this.ModifiedData.VisitorEmail;
          if (this.ModifiedData.VisitorPhoneLock)
            element.VisitorPhone = this.ModifiedData.VisitorPhone;
          if (this.ModifiedData.VisitorCountryLock)
            element.VisitorCountry = this.ModifiedData.VisitorCountry;
          if (this.ModifiedData.EntryTypeLock)
            element.EntryType = this.ModifiedData.EntryType;
        });
      }
      //}

      //Set Entry Limit based on the date change if entrypointgroup is selected
      if (
        this.ModifiedData.EntryPointGroupID != undefined &&
        this.ModifiedData.EntryPointGroupID > 0
      ) {
        this.setEntryLimitLastStep(
          this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
          this.datePipe.transform(this.selectedEndDate, "yyyy-MM-dd"),
          this.userService.organizationID,
          this.ModifiedData.EntryPointGroupID
        );
      }
      if (this.EntryType == 1 && this.guestPasses.length > 0) {
        this.setParkingLimit(
          this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
          this.datePipe.transform(this.selectedEndDate, "yyyy-MM-dd"),
          this.userService.organizationID
        );
      }
      if (this.guestPasses.length > 0) this.gridApi.sizeColumnsToFit();
    };
    fileReader.readAsArrayBuffer(this.file);
  }


  // format uploaded passes
  private formatPassUpload(json: Array<any>) {
    this.guestPasses = [];
    json.forEach((item) => {
      this.guestPasses.push({
        VisitorFirstName: item["Visitor First Name"],
        // VisitorMiddleName: item["Visitor Middle Name"],
        VisitorMiddleName: "",
        VisitorLastName: item["Visitor Last Name"],
        VisitorNameSuffix: item["Visitor Suffix"],
        VisitorCompany: item["Visitor Company"],
        VisitorEmail: item["Visitor Email"],
        VisitorPhone: item["Visitor Phone"],
        VisitorCountry: item["Visitor Country"],
        EntryType: +this.ModifiedData.EntryType,
      });
    });

    return this.guestPasses;
  }

  protected onGridReady(params) {
    this.gridApi = params.api;
    // this.gridApi.setColumnDefs(this.columnDefs);
    // this.gridApi.setRowData(this.rowData);
    this.gridApi.setColumnDefs(this.initColDef());
  }

  onCellKeyPress(event) {
    //disabling submit while editing cell
    this.enableSubmit = true;
    this.isValidationDisabled();
  }

  onCellKeyDown(e) {
      if(e.colDef.field == 'VisitorFirstName') e.data.VisitorFirstName = e.event.target.value;
      if(e.colDef.field == 'VisitorLastName') e.data.VisitorLastName = e.event.target.value;
      if(e.colDef.field == 'VisitorNameSuffix') e.data.VisitorNameSuffix = e.event.target.value;
      if(e.colDef.field == 'VisitorCompany') e.data.VisitorCompany = e.event.target.value;
      if(e.colDef.field == 'VisitorCountry') e.data.VisitorCountry = e.event.target.value;
      if(e.colDef.field == 'VisitorEmail') e.data.VisitorEmail = e.event.target.value;
      if(e.colDef.field == 'VisitorPhone') e.data.VisitorPhone = e.event.target.value;
  }
  // initialize column definitions
  private initColDef() {
    return (this.columnDefs = [
      {
        headerName: this.screenLabels["NameAgGrid"]
          ? this.screenLabels["NameAgGrid"]
          : "Name",
        children: [
          {
            headerName: "",
            field: "",
            width: 80,
            cellStyle: { color: "#589bf8" },
            hide: false,
            checkboxSelection: true,
            resizable: false,
            sortable: false,
            lockPosition: true,
          },
          {
            headerCheckboxSelection: false,
            headerCheckboxSelectionFilteredOnly: true,
            field: "TmpPassID",
            hide: true,
            // columnGroupShow: "closed",
          },
          {
            headerName: this.screenLabels["FirstName"]
              ? this.screenLabels["FirstName"]
              : "First Name *",
            field: "VisitorFirstName",
            editable: true,
            filter: true,
            // columnGroupShow: "closed",
            cellStyle: function (params) {
              if (
                DataValidator.isNotEmpty(params.value) !=
                ValidationErrorCodes.Ok
              ) {
                //make background red
                return { color: "black", backgroundColor: "red" };
              } else {
                // reset style
                return { color: "black", backgroundColor: "transparent" };
              }
            },
            tooltip: function (params) {
              const validationCode = DataValidator.isNotEmpty(params.value);
              if (validationCode !== ValidationErrorCodes.Ok) {
                return DataValidator.getErrorMessage(validationCode);
              }
            },
          },
          // {
          //   headerName: this.screenLabels["MiddleName"]
          //     ? this.screenLabels["MiddleName"]
          //     : "Middle Name",
          //   field: "VisitorMiddleName",
          //   hide: false,
          //   editable: true,
          //   filter: true,
          //   columnGroupShow: "open",
          // },
          {
            headerName: this.screenLabels["LastName"]
              ? this.screenLabels["LastName"]
              : "Last Name*",
            field: "VisitorLastName",
            editable: true,
            filter: true,
            // columnGroupShow: "closed",
            cellStyle: function (params) {
              if (
                DataValidator.isNotEmpty(params.value) !=
                ValidationErrorCodes.Ok 
              ) {
                //make background red
                return { color: "black", backgroundColor: "red" };
              } else {
                // reset style
                return { color: "black", backgroundColor: "transparent" };
              }
            },
            tooltip: function (params) {
              const validationCode = DataValidator.isNotEmpty(params.value);
              if (validationCode !== ValidationErrorCodes.Ok) {
                return DataValidator.getErrorMessage(validationCode);
              }
            },
          },
          {
            headerName: this.screenLabels["Suffix"]
              ? this.screenLabels["Suffix"]
              : "Suffix",
            field: "VisitorNameSuffix",
            hide: false,
            editable: true,
            filter: true,
            columnGroupShow: "open",
          },
        ],
      },
      {
        headerName: this.screenLabels["ContactInformationAgGrid"]
          ? this.screenLabels["ContactInformationAgGrid"]
          : "Contact Information",
        children: [
          {
            headerName: this.screenLabels["Company"]
              ? this.screenLabels["Company"]
              : "Company",
            field: "VisitorCompany",
            hide: false,
            editable: true,
            filter: true,
          },
          {
            headerName: this.screenLabels["PhoneNumber"]
              ? this.screenLabels["PhoneNumber"]
              : "Phone Number",
            field: "VisitorPhone",
            hide: false,
            editable: true,
            filter: true,
            columnGroupShow: "open",
          },
          {
            headerName: this.screenLabels["Country"]
            ? this.screenLabels["Country"]
            : "Country",
            field: "VisitorCountry",
            hide: this.siteService._siteHasCountry ? false : true,
            editable: true,
            filter: true,
            columnGroupShow: "open",
            cellEditor: "select",
            cellEditorParams: {
              values: this.extractValues(this.countries),
            },
            valueFormatter: (params) =>
              this.lookupValue(this.countries, params.value),
            valueParser: (params) =>
              this.lookupKey(this.countries, params.newValue),
          },
          {
            headerName: this.screenLabels["VisitorEmail"]
              ? this.screenLabels["VisitorEmail"]
              : "Email",
            field: "VisitorEmail",
            hide: false,
            editable: true,
            filter: true,
            cellRenderer: function (params) {
              const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,4}){1,2}$/;
              const value = params.value || "";
              if (!emailRegex.test(value) && value !== "") {
                return `<span style="color:red;">Invalid Email</span>`;
              }
              return params.value;
          },
          },
        ],
      },

      {
        headerName: "",
        children: [
          {
            headerName: this.screenLabels["EntryType"]
              ? this.screenLabels["EntryType"]
              : "Entry Type",
            field: "EntryType",
            editable: true,
            filter: true,
            hide: +this.copyPassForm.value.entryType == 2 || !this.siteService._siteHasParking ? true : false,
            cellEditor: "select",
            cellEditorParams: {
              values: this.getEntryTypeValues(),
            },
            valueFormatter: (params) =>
              this.lookupValue(this.entryTypeMappings, params.value),
            valueParser: (params) =>
              this.lookupKey(this.entryTypeMappings, params.newValue),
          },
        ],
      },
      {
        headerName: this.screenLabels["IsDuplicateAgGrid"]
          ? this.screenLabels["IsDuplicateAgGrid"]
          : "Is A Duplicate",
        field: "IsADuplicate",
        editable: false,
        filter: true,
        hide: true,
        // : false, //+this.createPassForm.value.entryType == 2,
        cellRenderer: (data) => {
          return data.value ? "Yes" : "No";
        },
      },
      {
        headerName: this.screenLabels["IgnoreDuplicateAgGrid"]
          ? this.screenLabels["IgnoreDuplicateAgGrid"]
          : "Ignore Duplicate",
        field: "IgnoreDuplicate",
        hide: !this.attemptedValidation,
        cellRenderer: function (params) {
          var input = document.createElement("input");
          input.type = "checkbox";
          input.checked = params.value;
          input.addEventListener("click", function (event: any) {
            const checked = event.target.checked;
            params.value = checked;

            // invoke on change event
            params.setValue(checked);

            params.node.data.IgnoreDuplicate = params.value;
          });
          return params.node.data.IsADuplicate ? input : null;
        },
      },
    ]);
  }

  // invoked when entry type radio button is changed
  onNewEntryTypeChange(event: MatRadioChange) {
    if (event.value == 1) {
      this.isParkingDisabled = false;
    } else {
      this.isParkingDisabled = true;
    }
    this.gridApi.setColumnDefs(this.initColDef());
    if (this.rowData.length > 0) this.gridApi.sizeColumnsToFit();
  }

  // invoked when a selected row is removed
  onRemoveSelected() {
    const selectedRow = this.gridApi.getSelectedNodes()[0];
    if (this.rowData.length !== 0) {
      const deleteVisitor = this.screenLabels["DeleteVisitor"] ? this.screenLabels["DeleteVisitor"] : "Delete Visitor";
      const message = this.screenLabels["DeleteWarningMsg"] ? this.screenLabels["DeleteWarningMsg"] : "Are you sure you want to delete the Row: <<id>> ?";
      const translatedMessage = message.replace("<<id>>", (selectedRow.rowIndex + 1));
      const deleteWarningMsg = this.screenLabels["DeleteWarningMsg"] ? translatedMessage : "Are you sure you want to delete the Row: " +
        (selectedRow.rowIndex + 1) +
        " ?";
      this.dialogService
        .openConfirmDialog(
          deleteVisitor,
          deleteWarningMsg
        )
        .afterClosed()
        .subscribe((confirm: boolean) => {
          if (confirm) {
            if (selectedRow.data.EntryType == 1) {
              --this.parkingCrossedBy;
            }
            this.rowData.splice(selectedRow.rowIndex, 1);
            this.guestPasses = this.rowData;

            if (
              selectedRow.data.EntryType == 1 &&
              this.ModifiedData.ParkingLotID > 0 &&
              this.parkingCrossedBy > 0
            ) {
              --this.parkingCrossedBy;
            } else {
              //Set Parking Limit based on date change if parking lot is selected
              if (
                selectedRow.data.EntryType == 1 &&
                this.ModifiedData.ParkingLotID != undefined &&
                this.ModifiedData.ParkingLotID > 0
              ) {
                this.setParkingLimit(
                  this.datePipe.transform(this.selectedStartDate, "yyyy-MM-dd"),
                  this.datePipe.transform(this.selectedEndDate, "yyyy-MM-dd"),
                  this.userService.organizationID
                );
                --this.parkingCrossedBy;
              }
            }
            if (
              this.ModifiedData.EntryPointGroupID > 0 &&
              this.entryPointCrossedBy > 0
            ) {
              --this.entryPointCrossedBy;
            }
            //this.rowData.splice(selectedRow.rowIndex, 1);
            this.gridApi.setRowData(this.rowData);
            this.isRowSelected = false;
            if (this.rowData.length == 0) {
              this.isRowSelected = false;
            }
          }
        });
    }
    this.passService.updateTempPassCheck(selectedRow.data, true).subscribe((res: any) => {
    })
  }

  // add empty row in ag-Grid
  onEmptyVisitorRowAdd() {
    this.gridApi.setColumnDefs(this.initColDef());
    this.enableSubmit = true;
    //for checking rowlimit
    if (this.rowData.length < 1000) {
      this.rowData.push({});
      this.isValidationDisabled();
    } else {
      this.rowData;
      const rowLimitReached = this.screenLabels["RowLimitReached"] ? this.screenLabels["RowLimitReached"] : "Row Limit Reached";
      const maximumNumberOfRows = this.screenLabels["MaximumNumberOfRows"] ? this.screenLabels["MaximumNumberOfRows"] : "Maximum number of rows is 1000";
      this.dialogService.openAlertDialogDialog(
        rowLimitReached,
        maximumNumberOfRows,
        "400px",
        "150px"
      );
    }

    this.guestPasses = this.rowData;
    this.gridApi.setRowData(this.rowData);
    this.finalPassTmpData = this.rowData;
    if (this.ModifiedData.entryPointGroupID > 0 && this.entryPointCrossedBy > 0) {
      ++this.entryPointCrossedBy;
    }

    this.tempPassesCheck(this.apiBody, this.guestPasses);
  }

  // Method to get entry type values based on parkingName
  private getEntryTypeValues() {
    if (this.ModifiedData.ParkingLotID) {
      return this.extractValues(this.entryTypeMappings); // Both options available
    } else {
      this.rowData.forEach(item => {
        item.EntryType = 2; // Set EntryType to 2 for each object
    });
      return [2]; // Only "Walk-on" option available
    }
  }

  // ag-grid entry type callbacks
  private entryTypeMappings = {
    1: "Drive-on",
    2: "Walk-on",
  };
  private extractValues(mappings) {
    return Object.keys(mappings);
  }
  private lookupValue(mappings, key) {
    return mappings[key];
  }
  private lookupKey(mappings, name) {
    var keys = Object.keys(mappings);
    for (var i = 0; i < keys.length; i++) {
      var key = keys[i];
      if (mappings[key] === name) {
        return key;
      }
    }
  }

  private createIndividualPass(apiBody) {
    this.finalPassTmpData = [];
    this.passService
      .createTempPassCheck(apiBody, true).subscribe((res: any) => {
        this.finalPassTmpData = res.body;
        const body = []
        body.push(apiBody)
        this.passService.passValidateGenUserTaskID(body).subscribe((res: any) => {
          const data = res.body;
          if (data.UserTaskID) {
            this.getUserTaskStatus(data.UserTaskID);
          } else {
            this.getDuplicatePasses(true);
          }
        });
      });
  }

  //get duplicate Entries
  getDuplicatePasses(singlePass?) {
    this.passService.getDuplicatePasses().subscribe((res: any) => {
      this.duplicateTempPassID = res.body.resultData;
      this.activateSpinner = false;
      // here we have to close the snackbar 
      this.userService.sendToggle(true);
      if (this.duplicateTempPassID.length > 0) {
        if (singlePass) {
          // duplicate found on a single pass, show option to allow duplicate
          const duplicatePassDetectMsg = this.screenLabels["DuplicatePassDetectMsg"] ? this.screenLabels["DuplicatePassDetectMsg"] : "Duplicate Pass Detected";
          const duplicatePassDetectWarningMsg = this.screenLabels["DuplicatePassDetectWarningMsg"] ? this.screenLabels["DuplicatePassDetectWarningMsg"] : "It looks like we have a Duplicate Pass in the System! Do you still want to create the Pass as a Duplicate ?";
          this.dialogService
            .openConfirmDialog(
              duplicatePassDetectMsg,
              duplicatePassDetectWarningMsg
            )
            .afterClosed()
            .subscribe((confirmation: boolean) => {
              if (confirmation) {
                //body[0].AllowDup = true;

                // retry creating the pass
                this.createPassAPINew()
              }
            });
        }
        else {
          this.enableSubmit = true;
          this.rowData.forEach(row => {
            this.duplicateTempPassID.forEach(data => {
              if (row.TmpPassID === data.TmpPassID) {
                row.IsADuplicate = true;
              }
            })
            this.gridApi.setRowData(this.rowData);
            this.finalPassTmpData = this.rowData;
          })
        }
      } else if (this.duplicateTempPassID.length === 0) {
        this.enableSubmit = false;
        if (this.partyPassFlag || singlePass) {
          if (this.partyPassFlag) {
            this.passService.generatePartyPass().subscribe((res: any) => {
              const response = res.body;
              this.partyID = response.PartyID;
              this.createPassAPINew();
            });
          } else if (singlePass)
            this.createPassAPINew();
        }
      }
    },
      (err) => {
        console.log(err);
        console.log(err.message);
      })
  }

  onCreatePassTemp() {
    this.partyPassFlag = true;
    const reqdata = this.formatBulkData(this.rowData);
    this.passService.createTempPassCheck(reqdata).subscribe((res: any) => {
        this.rowData = res.body
        this.finalPassTmpData = res.body;
      });
    this.validateBulkUploadData();
  }

  createPassAPINew() {
    this.activateSpinner = true;
    const data = {
      passLength: this.finalPassTmpData.length,
      tmpPassId: this.finalPassTmpData[0].TmpPassID
    }
    this.passService.createPassAPINew(data).subscribe((res: any) => {
      if (res.body.UserTaskID) {
        this.UserTaskID = res.body.UserTaskID;
        this.getUserTaskStatus(res.body.UserTaskID, true);
      }
      else if(res.body.PassCreatedCount < this.finalPassTmpData.length) {
        this.activateSpinner = false;
        this.onNoClick();
        this.passService.dataChanged.emit(true);
        if (this.finalPassTmpData.length > 1) {
          var message = this.screenLabels["PassSuccessFailCountMsg"] ? this.screenLabels["PassSuccessFailCountMsg"] : "<<count1>> Passes were created successfully, <<count2>> passes were failed. Please check host-email for more information"
          const msg = message.replace("<<count1>>", res.body.PassCreatedCount).replace("<<count2>>", this.finalPassTmpData.length - res.body.PassCreatedCount)
          this.notificationService.success(msg);
        }
        else {
          var message = this.screenLabels["PassSuccessFailCountSingleMsg"] ? this.screenLabels["PassSuccessFailCountSingleMsg"] : "Pass creation was failed. Please check host-email for more information"
          this.notificationService.success(message);
        }
      }
       else if (res.body.message === "Pass is created successfully") {
        this.activateSpinner = false;
        this.onNoClick();
        this.passService.dataChanged.emit(true);
        this.notificationService.success(res.body.message);
      }
    },
      (err) => {
        this.activateSpinner = false;
        console.log(err);
        console.log(err.message);
      })
  }

  getUserTaskStatus(data, onPassCreateClick?) {

    var statusId = setInterval(() => {
      this.passService.getUserTaskStatus(data).subscribe((res: any) => {
        const data = res.body;
        //userTaskStatus, cleanUpStatus
        if (data.userTaskStatus === "Succeeded" || data.userTaskStatus === "Failed" || data.userTaskStatus === "Cancelled" || data.userTaskStatus === "Killed" || data.userTaskStatus === "Timed Out") {
          if (data.userTaskStatus === "Succeeded" && onPassCreateClick) {
            this.onNoClick();
            clearInterval(statusId);
            this.activateSpinner = false;
            const passCreationMsg = this.screenLabels["PassCreationMsg"] ? this.screenLabels["PassCreationMsg"] : "Passes created Successfully";
            this.notificationService.success(passCreationMsg);
          } else if (data.userTaskStatus === "Succeeded") {
            this.getDuplicatePasses();
            clearInterval(statusId);
          } else if (data.userTaskStatus === "Killed") {
            if (this.killCount > 2) {
              this.userService.sendCloseButtonToggle(true);
              const processKilledAdminMsg = this.screenLabels["ProcessKilledAdminMsg"] ? this.screenLabels["ProcessKilledAdminMsg"] : "Process has been killed, Please contact administrator";
              this.userService.setSnackBarMessage(processKilledAdminMsg);
              clearInterval(statusId);
            } else {
              if (data.cleanUpStatus === "Processing" || data.cleanUpStatus === null) {
                // this.showBasicSnackBarComponent();
                const processKilledCleanupMsg = this.screenLabels["ProcessKilledCleanupMsg"] ? this.screenLabels["ProcessKilledCleanupMsg"] : "Process has been killed, Cleanup is processing...";
                this.userService.setSnackBarMessage(processKilledCleanupMsg);
              }
              else if (data.userTaskStatus === "Killed" && data.cleanUpStatus === "Succeeded") {
                this.killCount = this.killCount + 1;
                //here modal should be open only but the snackbar closed
                this.userService.sendToggle(true);
                this.activateSpinner = false;
                clearInterval(statusId);
              } else {
                this.userService.sendCloseButtonToggle(true);
                const processKilledCleanUpFailedAdminMsg = this.screenLabels["ProcessKilledCleanUpFailedAdminMsg"] ? this.screenLabels["ProcessKilledCleanUpFailedAdminMsg"] : "Process has been killed and CleanUp also failed. Please contact administrator";
                this.userService.setSnackBarMessage(processKilledCleanUpFailedAdminMsg);
              }
            }
          } else if (data.userTaskStatus === "Timed Out") {
            this.userService.sendCloseButtonToggle(false);
            if (data.cleanUpStatus === "Processing" || data.cleanUpStatus === null) {
              const processTimedoutCleanUpProcessingMsg = this.screenLabels["ProcessTimedoutCleanUpProcessingMsg"] ? this.screenLabels["ProcessTimedoutCleanUpProcessingMsg"] : "Process has been Timedout, Cleanup is processing...";
              this.userService.setSnackBarMessage(processTimedoutCleanUpProcessingMsg);
            }
            else if (data.userTaskStatus === "Timed Out" && data.cleanUpStatus === "Succeeded") {
              this.userService.sendCloseButtonToggle(true);
              const processTimedoutCleanUpCompletedMsg = this.screenLabels["ProcessTimedoutCleanUpCompletedMsg"] ? this.screenLabels["ProcessTimedoutCleanUpCompletedMsg"] : "Process has been Timedout, Cleanup is completed. Close the modal";
              this.userService.setSnackBarMessage(processTimedoutCleanUpCompletedMsg);
              clearInterval(statusId);
            } else {
              this.userService.sendCloseButtonToggle(true);
              const processTimedoutCleanUpFailedAdminMsg = this.screenLabels["ProcessTimedoutCleanUpFailedAdminMsg"] ? this.screenLabels["ProcessTimedoutCleanUpFailedAdminMsg"] : "Process has been Timed Out and CleanUp also failed. Please contact administrator";
              this.userService.setSnackBarMessage(processTimedoutCleanUpFailedAdminMsg);
            }
          } else if (data.userTaskStatus === "Failed") {
            this.userService.sendCloseButtonToggle(false);
            if (data.cleanUpStatus === "Processing" || data.cleanUpStatus === null) {
              const processFailedCleanUpProcessingMsg = this.screenLabels["ProcessFailedCleanUpProcessingMsg"] ? this.screenLabels["ProcessFailedCleanUpProcessingMsg"] : "Process has been Failed, Cleanup is processing...";
              this.userService.setSnackBarMessage(processFailedCleanUpProcessingMsg);
            }
            else if (data.userTaskStatus === "Failed" && data.cleanUpStatus === "Succeeded") {
              this.userService.sendCloseButtonToggle(true);
              const processFailedCleanUpCompletedMsg = this.screenLabels["ProcessFailedCleanUpCompletedMsg"] ? this.screenLabels["ProcessFailedCleanUpCompletedMsg"] : "Process has been Failed, Cleanup is completed. Close the modal";
              this.userService.setSnackBarMessage(processFailedCleanUpCompletedMsg);
              clearInterval(statusId);
            } else {
              this.userService.sendCloseButtonToggle(true);
              const processFailedCleanUpFailedAdminMsg = this.screenLabels["ProcessFailedCleanUpFailedAdminMsg"] ? this.screenLabels["ProcessFailedCleanUpFailedAdminMsg"] : "Process has been Failed and CleanUp also failed. Please contact administrator";
              this.userService.setSnackBarMessage(processFailedCleanUpFailedAdminMsg);
            }
          }
          else if (data.userTaskStatus === "Cancelled") {
            this.showBasicSnackBarComponent();
            this.userService.sendCloseButtonToggle(false);
            if (data.cleanUpStatus === "Processing" || data.cleanUpStatus === null) {
              const processCancelledCleanUpProcessingMsg = this.screenLabels["ProcessCancelledCleanUpProcessingMsg"] ? this.screenLabels["ProcessCancelledCleanUpProcessingMsg"] : "Process has been cancelled, Cleanup is processing...";
              this.userService.setSnackBarMessage(processCancelledCleanUpProcessingMsg);
            }
            else if (data.userTaskStatus === "Cancelled" && data.cleanUpStatus === "Succeeded") {
              const processCancelledCleanUpCompletedMsg = this.screenLabels["ProcessCancelledCleanUpCompletedMsg"] ? this.screenLabels["ProcessCancelledCleanUpCompletedMsg"] : "Process has been Cancelled, Cleanup is completed. Close the modal";
              this.userService.setSnackBarMessage(processCancelledCleanUpCompletedMsg);
              this.userService.sendCloseButtonToggle(true);
              clearInterval(statusId);
            }
            else {
              this.userService.sendCloseButtonToggle(true);
              const processCancelledCleanUpFailedAdminMsg = this.screenLabels["ProcessCancelledCleanUpFailedAdminMsg"] ? this.screenLabels["ProcessCancelledCleanUpFailedAdminMsg"] : "Process has been Cancelled and CleanUp also failed. Please contact administrator";
              this.userService.setSnackBarMessage(processCancelledCleanUpFailedAdminMsg);
            }
          }
        }
        this.passService.getPartyPassCreatedCountAPI(this.partyID).subscribe((res:HttpResponse<any>) => {
          if (res.body.PartyPassCreatedCount < this.finalPassTmpData.length) {
            var message = this.screenLabels["PassSuccessFailCountMsg"] ? this.screenLabels["PassSuccessFailCountMsg"] : "<<count1>> Passes were created successfully, <<count2>> passes were failed. Please check host-email for more information"
            const msg = message.replace("<<count1>>", res.body.PartyPassCreatedCount).replace("<<count2>>", this.finalPassTmpData.length - res.body.PartyPassCreatedCount)
            this.notificationService.success(msg);
          }
        })
      },
        (err) => {
          console.log(err);
          console.log(err.message);
        })
    }, 5000)
  }

  showBasicSnackBarComponent() {

    this._snackBar.openFromComponent(BasicSnackbarComponent, {
      data: this.UserTaskID,
      duration: 10000000,
      panelClass: ["custom-style"]
    });
  }

  // logic to create bulk basses
  private createBulkPasses(apiBody, visitorData: Array<any>) {
    if (visitorData.length > 0) {
      const passes = [];
      visitorData.forEach((visitor) => {
        const tempPass = { ...apiBody };
        !visitor.EntryType ? this.apiBody.EntryType : visitor.EntryType;
        tempPass.VisitorCompany = !visitor.VisitorCompany
          ? null
          : visitor.VisitorCompany;
        tempPass.VisitorEmail = !visitor.VisitorEmail
          ? null
          : visitor.VisitorEmail;
        tempPass.VisitorFirstName = !visitor.VisitorFirstName
          ? null
          : visitor.VisitorFirstName;
        tempPass.VisitorLastName = !visitor.VisitorLastName
          ? null
          : visitor.VisitorLastName;
        // tempPass.VisitorMiddleName = !visitor.VisitorMiddleName
        //   ? null
        //   : visitor.VisitorMiddleName;
        tempPass.VisitorMiddleName = "";
        tempPass.VisitorNameSuffix = !visitor.VisitorNameSuffix
          ? null
          : visitor.VisitorNameSuffix;
        tempPass.VisitorPhone = !visitor.VisitorPhone
          ? null
          : visitor.VisitorPhone.toString();
        tempPass.VisitorCountry = !visitor.VisitorCountry
          ? null
          : visitor.VisitorCountry;
        if (visitor.IgnoreDuplicate === true) tempPass.AllowDup = true;

        passes.push(tempPass);
      });
      this.passService
        .createPassAPI(passes)
        .then(() => {
          this.notificationService.success(this.passService.message);
        })
        .catch((err: HttpErrorResponse) =>
          this.notificationService.success(err.message)
        );
    }
  }

  // login to create party
  private createParty(apiBody, visitorData: Array<any>) {
    if (this.rowData.length > this.PARTY_LIMIT) {
      // consider a bulk upload
      this.createBulkPasses(apiBody, visitorData);
    } else {
      // create a party
      this.passService
        .getPartyIDAPI()
        .then(() => {
          const partyID = this.passService.partyID;
          this.apiBody.PartyID = partyID;

          this.createBulkPasses(apiBody, visitorData);
        })
        .catch((err: HttpErrorResponse) =>
          this.notificationService.failure(err.message)
        );
    }
  }

  // gets the data from Ag Grid
  private getGridData() {
    const rowData = [];
    if (this.gridApi != undefined)
      this.gridApi.forEachNode((node) => rowData.push(node.data));

    return [...rowData];
  }

  // invoked when ag-Grid's cell data is changed
  protected onCellDataChanged(event) {
    const rowIndex = event.rowIndex;
    const columnName = event.colDef.field;
    const api = event.api;
    const rowData = api.gridOptionsWrapper.gridOptions.rowData;
    const oldData = event.oldValue;
    const newData = event.newValue;

    if (oldData != newData && columnName === "IgnoreDuplicate") {
      rowData[rowIndex].IgnoreDuplicate = newData;
      this.gridApi.setRowData(rowData);
    }

    if (oldData != newData && columnName !== "IgnoreDuplicate") {
      rowData[rowIndex].IsADuplicate = false;
      // rowData[rowIndex].IgnoreDuplicate = false;
      this.gridApi.setRowData(rowData);
    }

    if (columnName === "EntryType" && newData === "2") {
      // reduce the parking overbooking status message if applicable
      --this.parkingCrossedBy;
    }

    if (columnName === "EntryType" && newData === "1") {
      // reduce the parking overbooking status message if applicable
      ++this.parkingCrossedBy;
    }
  }

  // invoked to display the legend
  protected showLegend() {
    const html =`
    <table>
      <tr>
        <th class="legend-text">${this.screenLabels["Color"] ?
        this.screenLabels["Color"]: "Color"}</th>
        <th class="legend-text">${this.screenLabels["Description"] ?
        this.screenLabels["Description"]: "Description"}</th>
      </tr>
      <tr>
        <td class="legend-text"><span class="row-warning legend-info">&nbsp;</span></td>
        <td class="legend-text">${this.screenLabels["DuplicateRecordFoundMsg"] ?
        this.screenLabels["DuplicateRecordFoundMsg"]: "Duplicate Record Found"}</td>
      </tr>
      <tr>
        <td class="legend-text"><span class="row-ignore legend-info">&nbsp;</span></td>
        <td class="legend-text">${this.screenLabels["DuplicateRecordIgnoredMsg"] ?
        this.screenLabels["DuplicateRecordIgnoredMsg"]: "Duplicate Record Ignored"}</td>
      </tr>
    </table>
    `;
    this.dialogService.openAlertDialogDialog(this.screenLabels["Legend"] ? this.screenLabels["Legend"] :"Legend", html, "400px", "260px");
  }

  public validateBulkUploadData() {

    this.userService.sendToggle(false);
    this.activateSpinner = true;
    const rowData = this.getGridData();

    this.validationErrors = [];
    setTimeout(() => {
      for (let i = 0; i < rowData.length; i++) {
        const entryType = rowData[i].EntryType
          ? rowData[i].EntryType
          : this.EntryType;
        const visitorCompany = rowData[i].VisitorCompany;
        const visitorEmail = rowData[i].VisitorEmail;
        const visitorFirstName = rowData[i].VisitorFirstName;
        const visitorLastName = rowData[i].VisitorLastName;
        // const visitorMiddleName = rowData[i].VisitorMiddleName;
        const visitorMiddleName = "";
        const visitorNameSuffix = rowData[i].VisitorNameSuffix;
        const visitorPhone = rowData[i].VisitorPhone;
        const visitorCountry = rowData[i].VisitorCountry;

        // check email format
        if (
          visitorEmail &&
          DataValidator.isEmail(visitorEmail) != ValidationErrorCodes.Ok
        ) {
          this.validationErrors.push({
            rowNumber: i + 1,
            errorText: DataValidator.getErrorMessage(
              DataValidator.isEmail(visitorEmail)
            ),
            fieldName: this.screenLabels["VisitorEmail"] ?
              this.screenLabels["VisitorEmail"] : "Visitor Email",
          });
        }

        // check if visitor first name not empty
        if (
          DataValidator.isNotEmpty(visitorFirstName) != ValidationErrorCodes.Ok
        ) {
          this.validationErrors.push({
            rowNumber: i + 1,
            errorText: DataValidator.getErrorMessage(
              DataValidator.isNotEmpty(visitorFirstName)
            ),
            fieldName: this.screenLabels["VisitorFirstName"] ?
              this.screenLabels["VisitorFirstName"] : "Visitor First Name",
          });
        }

        // check if visitor last name not empty
        if (
          DataValidator.isNotEmpty(visitorLastName) != ValidationErrorCodes.Ok
        ) {
          this.validationErrors.push({
            rowNumber: i + 1,
            errorText: DataValidator.getErrorMessage(
              DataValidator.isNotEmpty(visitorLastName)
            ),
            fieldName: this.screenLabels["VisitorLastName"] ?
              this.screenLabels["VisitorLastName"] : "Visitor Last Name",
          });
        }

        // check if entry type is correct
        if (entryType < 1 || entryType > 2) {
          this.validationErrors.push({
            rowNumber: i + 1,
            errorText: this.screenLabels["InvalidEntryType"] ? this.screenLabels["InvalidEntryType"] : "Invalid Entry Type",
            fieldName: this.screenLabels["EntryType"] ? this.screenLabels["EntryType"] : "Entry Type",
          });
        }

      }

    })
    this.gridApi.setRowData(this.rowData);
    this.finalPassTmpData = this.rowData;
    this.attemptedValidation = true;
    this.gridApi.setColumnDefs(this.initColDef());
    this.gridApi.sizeColumnsToFit();
    const api: any = { ...this.gridApi };

    this.rowData = [...api.gridOptionsWrapper.gridOptions.rowData];
    const formattedData = this.formatBulkData(this.rowData);

    this.passService.passValidateGenUserTaskID(this.rowData).subscribe((res: any) => {
      const data = res.body;
      if (data.UserTaskID) {
        this.UserTaskID = data.UserTaskID;
        this.getUserTaskStatus(data.UserTaskID);
        this.showBasicSnackBarComponent();
      } else {
        this.getDuplicatePasses();
        const rowData = this.getGridData();
        // if(this.duplicateTempPassID.length == 0 ) {
        //   this.enableSubmit = false;
        // } else {
        //   this.enableSubmit = true;
        // }
      }
    },
      (err) => {
        console.log(err);
        console.log(err.message);
      })

    //Set Entry Limit based on date change if entry point group is selected
    if (this.apiBody.EntryPointGroupID != undefined && this.apiBody.EntryPointGroupID > 0) {
      this.setEntryLimitLastStep(
        this.apiBody.StartTime,
        this.apiBody.EndTime,
        this.userService.organizationID,
        this.apiBody.EntryPointGroupID
      );
    }
    //Set Parking Limit based on date change if parking lot is selected
    if (this.apiBody.ParkingLotID != undefined && this.apiBody.ParkingLotID > 0) {
      this.setParkingLimit(
        this.datePipe.transform(this.apiBody.StartTime, "yyyy-MM-dd"),
        this.datePipe.transform(this.apiBody.EndTime, "yyyy-MM-dd"),
        this.userService.organizationID
      );
    }
  }

  // format the passes in accordance with Pass Validation API
  private formatBulkData(rowData: Array<any>) {
    this.apiBody.Authorizer = this.userService.userID;
    this.apiBody.VisitorFirstName = !this.copyPassForm.value.visitorFirstName
      ? null
      : this.copyPassForm.value.visitorFirstName;
    this.apiBody.VisitorLastName = !this.copyPassForm.value.visitorLastName
      ? null
      : this.copyPassForm.value.visitorLastName;
    // this.apiBody.VisitorMiddleName = !this.copyPassForm.value.visitorMiddleName
    //   ? null
    //   : this.copyPassForm.value.visitorMiddleName;
    this.apiBody.VisitorMiddleName = "";
    this.apiBody.VisitorNameSuffix = !this.copyPassForm.value.visitorNameSuffix
      ? null
      : this.copyPassForm.value.visitorNameSuffix;
    this.apiBody.VisitorCompany = !this.copyPassForm.value.visitorCompany
      ? null
      : this.copyPassForm.value.visitorCompany;
    this.apiBody.VisitorEmail = !this.copyPassForm.value.visitorEmail
      ? null
      : this.copyPassForm.value.visitorEmail;
    this.apiBody.VisitorPhone = !this.copyPassForm.value.visitorPhone
      ? null
      : this.copyPassForm.value.visitorPhone;
    this.apiBody.VisitorCountry = !this.copyPassForm.value.visitorCountry
      ? null
      : this.copyPassForm.value.visitorCountry;
    this.apiBody.StartTime = !this.copyPassForm.value.startTime
      ? null
      : this.datePipe.transform(
        this.copyPassForm.value.startTime,
        "yyyy-MM-dd"
      );
    this.apiBody.EndTime = !this.copyPassForm.value.endTime
      ? null
      : this.datePipe.transform(this.copyPassForm.value.endTime, "yyyy-MM-dd");
    this.apiBody.EstArrivalTime = !this.copyPassForm.value.estArrivalTime
      ? null
      : this.formatDate(this.copyPassForm.value.estArrivalTime);
    // this.datePipe.transform(
    //     this.copyPassForm.value.estArrivalTime,
    //     "yyyy-MM-dd HH:mm"
    //   );

    this.apiBody.EstDepartureTime = !this.copyPassForm.value.estDepartureTime
      ? null
      : this.formatDate(this.copyPassForm.value.estDepartureTime);
    // this.datePipe.transform(
    //     this.copyPassForm.value.estDepartureTime,
    //     "yyyy-MM-dd HH:mm"
    //   );

    this.apiBody.VisitReason = !this.copyPassForm.value.visitReason
      ? null
      : +this.copyPassForm.value.visitReason;
    this.apiBody.IncludeNonWorkingdays = !this.copyPassForm.value
      .includeNonWorkingdays
      ? null
      : this.copyPassForm.value.includeNonWorkingdays;
    this.apiBody.ExpectedEntryPoint = !this.copyPassForm.value
      .expectedEntryPoint
      ? null
      : +this.copyPassForm.value.expectedEntryPoint;
    this.apiBody.EntryPointGroupID = !this.ModifiedData.EntryPointGroupID
      ? null
      : +this.ModifiedData.EntryPointGroupID;
    this.apiBody.EntryType = !this.copyPassForm.value.entryType
      ? null
      : this.copyPassForm.value.entryType;
    this.apiBody.NotesInternal = !this.copyPassForm.value.notesInternal
      ? null
      : this.copyPassForm.value.notesInternal;
    this.apiBody.NotesPass = !this.copyPassForm.value.notesPass
      ? null
      : this.copyPassForm.value.notesPass;
    this.apiBody.ParkingLotID =
      this.apiBody.EntryType == 1 ? +this.ModifiedData.ParkingLotID : null;
    this.apiBody.DestinationBldg = !this.chosenBuildingId
      ? null
      : +this.chosenBuildingId;
    this.apiBody.DestinationBlgFlr = this.ModifiedData.DestinationBlgFlr ? this.ModifiedData.DestinationBlgFlr : null;
    this.apiBody.DestinationRoom = !this.copyPassForm.value.destinationBlgRoom
      ? null
      : this.copyPassForm.value.destinationBlgRoom;
    this.apiBody.HostFirstName = this.disabled
      ? this.userService.loggedInUserFirstName
      : this.ModifiedData.HostFirstName;
    // this.apiBody.HostMiddleName = this.copyPassForm.value
    //   .isHostAndAuthorizerSame
    //   ? this.userService.loggedInUserMiddleName
    //   : this.copyPassForm.value.hostMiddleName;
    this.apiBody.HostMiddleName = "";
    this.apiBody.HostLastName = this.disabled
      ? this.userService.loggedInUserLastName
      : this.ModifiedData.HostLastName;
    this.apiBody.HostNameSuffix = null;
    this.apiBody.HostCompany = this.disabled
      ? this.userService.userOrganizations.filter(
        (f) => f.OrganizationID == this.userService.organizationID
      )[0].Name
      : this.ModifiedData.HostCompany;
    this.apiBody.HostPhone = !this.ModifiedData.HostPhone
      ? null
      : this.ModifiedData.HostPhone;
    var hostEmails: String[] = [];
    this.hostEmails.forEach((element) => {
      if (element.valid === "valid") {
        hostEmails.push(element.email);
      }
    });
    this.apiBody.HostEmail = this.disabled
      ? this.userService.loggedInEmail
      : this.hostEmails.length > 0
        ? hostEmails.join(";")
        : null;
    this.apiBody.HostTWID = null;
    this.apiBody.OrganizationID = this.userService.organizationID;
    this.apiBody.Authorizer = this.userService.userID;
    this.apiBody.Operator =
      this.userService.loggedInUserID > 0
        ? this.userService.loggedInUserID
        : this.userService.userID;
    this.apiBody.PhotoPass = null;
    this.apiBody.Active = true;
    this.apiBody.CopyFromPassID = this.ModifiedData.PassID;
    this.apiBody.EmployeeRef = null;
    this.apiBody.UserID = this.userService.userID;
    this.apiBody.AllowDup = false;

    // add visitor details
    const visitorPasses = [];
    rowData.forEach((item) => {
      let tempApiBody = { ...this.apiBody };
      tempApiBody.EntryType = !item.EntryType
        ? this.apiBody.EntryType
        : item.EntryType;
      tempApiBody.IsADuplicate = item.IsADuplicate;
      tempApiBody.VisitorCompany = !item.VisitorCompany
        ? null
        : item.VisitorCompany;
      tempApiBody.VisitorEmail = !item.VisitorEmail ? null : item.VisitorEmail;
      tempApiBody.VisitorFirstName = !item.VisitorFirstName
        ? null
        : item.VisitorFirstName;
      tempApiBody.VisitorLastName = !item.VisitorLastName
        ? null
        : item.VisitorLastName;
      // tempApiBody.VisitorMiddleName = !item.VisitorMiddleName
      //   ? null
      //   : item.VisitorMiddleName;
      tempApiBody.VisitorMiddleName = "";
      tempApiBody.VisitorNameSuffix = !item.VisitorNameSuffix
        ? null
        : item.VisitorNameSuffix;
      tempApiBody.VisitorCountry = !item.VisitorCountry
        ? null
        : item.VisitorCountry;
      tempApiBody.VisitorPhone = !item.VisitorPhone ? null : item.VisitorPhone.toString();

      visitorPasses.push(tempApiBody);
    });

    return visitorPasses;
  }

  // invoked to check if the request could be submitted
  protected isSubmittable() {
    if (this.isMultiplePassSelected) {
      if (this.uniquePasses.length > 0 && this.validationErrors.length === 0)
        return true;
      else return false;
    } else {
      return true;
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || "").trim()) {
      if (DataValidator.isEmail(value) == ValidationErrorCodes.Ok) {
        this.hostEmails.push({ email: value.trim(), valid: "valid" });
        this.emailValidation = false;
      } else {
        this.hostEmails.push({ email: value.trim(), valid: "invalid" });
        this.emailValidation = true;
      }
    }
    // Reset the input value

    if (input) {
      input.value = "";
    }
  }

  remove(index: number): void {
    if (index >= 0) {
      this.hostEmails.splice(index, 1);
    }
  }

  onEmailValueChange(index: number): void {
    var email = "hostEmail" + index;
    var changedValue = this.copyPassForm.value[email];
    if ((changedValue || "").trim()) {
      if (DataValidator.isEmail(changedValue) == ValidationErrorCodes.Ok) {
        this.hostEmails[index] = { email: changedValue.trim(), valid: "valid" };
      } else {
        this.hostEmails[index] = {
          email: changedValue.trim(),
          valid: "invalid",
        };
      }
    }
  }

  HostIsSameAsAuthoriser() {
    if (this.isIgnoreLocks) {
      const userDetails = this.userService.getUserDetails();
      if (!this.disabled) {
        this.ModifiedData.HostFirstName = userDetails[0].PrefFirstName ? userDetails[0].PrefFirstName : this.userService.loggedInUserFirstName;
        // this.ModifiedData.HostMiddleName = this.userService.loggedInUserMiddleName;
        this.ModifiedData.HostMiddleName = "";
        this.ModifiedData.HostLastName = userDetails[0].PrefLastName ? userDetails[0].PrefLastName : this.userService.loggedInUserLastName;
        this.ModifiedData.HostNameSuffix = this.userService.loggedInSuffix;
        this.hostEmails = [];
        this.hostEmails.push({ email: userDetails[0].PrefEmail ? userDetails[0].PrefEmail : this.userService.loggedInEmail, valid: "valid" });
        this.ModifiedData.HostPhone = userDetails[0].PrefPhone ? userDetails[0].PrefPhone : this.userService.loggedInPhone;
        this.ModifiedData.HostCompany = this.userService.userOrganizations.filter(
          (f) => f.OrganizationID == this.userService.organizationID
        )[0].Name;
      } else {
        this.ModifiedData.HostFirstName = "";
        this.ModifiedData.HostMiddleName = "";
        this.ModifiedData.HostLastName = "";
        this.ModifiedData.HostNameSuffix = "";
        this.hostEmails = [];
        this.ModifiedData.HostPhone = "";
        this.ModifiedData.HostCompany = "";
      }
    }
  }
  timeValidation () {
  let DepatureDate = moment(this.copyPassForm.value.endTime).format("YYYY-MM-DD");
    let DepatureTime = moment(this.copyPassForm.value.estDepartureTime).format("HH:mm:ss");
    let DepatureTimeDate = moment(DepatureDate + ' ' + DepatureTime);
    let ArrivalDate = moment(this.copyPassForm.value.startTime).format("YYYY-MM-DD");
    let ArrivalTime = moment(this.copyPassForm.value.estArrivalTime).format("HH:mm:ss");
    let ArrivalTimeDate = moment(ArrivalDate + ' ' + ArrivalTime);
    if (this.formatDate(ArrivalTimeDate) > this.formatDate(DepatureTimeDate)) {
      this.errorMessage = this.screenLabels["TimeErrorMsg"] ? this.screenLabels["TimeErrorMsg"] : "Departure time should be greater than Arrival Time";
      this.isInvalid = true;
      this.enableNext = true;
    }
    else {
      this.isInvalid = false;
      this.enableNext = false;
    }
}

  OnArrivalTimeChange(event) {
    let DepatureDate = moment(this.copyPassForm.value.endTime).format("YYYY-MM-DD");
    let DepatureTime = moment(this.copyPassForm.value.estDepartureTime).format("HH:mm:ss");
    let DepatureTimeDate = moment(DepatureDate + ' ' + DepatureTime);
    let ArrivalDate = moment(this.copyPassForm.value.startTime).format("YYYY-MM-DD");
    let ArrivalTime = moment(this.copyPassForm.value.estArrivalTime).format("HH:mm:ss");
    let ArrivalTimeDate = moment(ArrivalDate + ' ' + ArrivalTime);
    if (this.estArrivalTime.cleared) {
      event.newValue = "Invalid Date"
    }
    //this.ModifiedData.EstDepartureTime = '';
    if (event.newValue == "Invalid Date" || this.ModifiedData.EstArrivalTime == "Invalid Date") {
      this.ModifiedData.EstArrivalTime = "";
    } else {
      if (this.siteService.dataTimeFormat.angularTimeFormat == '12 hours') {
        this.minTime = moment(this.ModifiedData.EstArrivalTime).format(
          "hh:mm tt"
        );
      } else {
        this.minTime = moment(this.ModifiedData.EstArrivalTime).format(
          "HH:mm"
        );
      }
    }
    if (this.formatDate(ArrivalTimeDate) > this.formatDate(DepatureTimeDate)) {
      this.errorMessage = this.screenLabels["TimeErrorMsg"] ? this.screenLabels["TimeErrorMsg"] : "Departure time should be greater than Arrival Time";
      this.isInvalid = true;
      this.enableNext = true;
    }
    else {
      this.isInvalid = false;
      this.enableNext = false;
    }
  }
  OnDepartureTimeChange(event) {
    if (this.estDepartureTime.cleared) {
      event.newValue = "Invalid Date"
    }
    this.isInvalid = false;
    if (event.newValue == "Invalid Date" || this.ModifiedData.EstDepartureTime == "Invalid Date") {
      this.ModifiedData.EstDepartureTime = "";
      this.errorMessage = this.screenLabels["TimeInvalidMsg"] ? this.screenLabels["TimeInvalidMsg"] : "Departure time is Invalid";
      this.isInvalid = true;
    } else {
      if (this.siteService.dataTimeFormat.angularTimeFormat == '12 hours') {
        this.minTime = moment(this.ModifiedData.EstDepartureTime).format(
          "hh:mm tt"
        );
      } else {
        this.minTime = moment(this.ModifiedData.EstDepartureTime).format(
          "HH:mm"
        );
      }
    } 
    let DepatureDate = moment(this.copyPassForm.value.endTime).format("YYYY-MM-DD");
    let DepatureTime = moment(this.copyPassForm.value.estDepartureTime).format("HH:mm:ss");
    let DepatureTimeDate = moment(DepatureDate + ' ' + DepatureTime);
    let ArrivalDate = moment(this.copyPassForm.value.startTime).format("YYYY-MM-DD");
    let ArrivalTime = moment(this.copyPassForm.value.estArrivalTime).format("HH:mm:ss");
    let ArrivalTimeDate = moment(ArrivalDate + ' ' + ArrivalTime);
    if (this.formatDate(ArrivalTimeDate) > this.formatDate(DepatureTimeDate)) {
      this.errorMessage = this.screenLabels["TimeErrorMsg"] ? this.screenLabels["TimeErrorMsg"] : "Departure time should be greater than Arrival Time";
      this.isInvalid = true;
      this.enableNext = true;
    }
    else {
      this.isInvalid = false;
      this.enableNext = false;
    }
  }

  OnIncludeNonWorkingDays() {
    if (!this.ModifiedData.IncludeNonWorkingdays) {
      this.checkDateValidation();
    }
    if (this.checkedDates) {
      if (this.ModifiedData.IncludeNonWorkingdays) {
        this.checkedDates = false;
      }
    }
    if (this.checkDatesError) {
      if (this.ModifiedData.IncludeNonWorkingdays) {
        this.enableNext = false;
        this.checkDatesError = false;
      }
      else {
        this.enableNext = true;
      }
    }
    if (this.entryPoinIndicator) {
      if (this.ModifiedData.IncludeNonWorkingdays) {
        this.enableNext = false;
        this.entryPoinIndicator = false;
      }
      else {
        this.enableNext = true;
      }
    }
  }

  checkDateValidation() {
    this.checkedDates = false;
    let formattedStart = moment(this.ModifiedData.StartTime).format("YYYY-MM-DD");
    let formattedEnd = moment(this.ModifiedData.EndTime).format("YYYY-MM-DD");
    let start = Date.parse(formattedStart);
    let end = Date.parse(formattedEnd);

    this.holidays.forEach(element => {
      if ((Date.parse(element) <= end) && (Date.parse(element) >= start)) {
        this.checkedDates = true;
        return this.checkedDates;
      }
    })
    return this.checkedDates;
  }

  checkDatesOnNext() {
    this.checkDatesNext = false;
    let formattedStart = moment(this.ModifiedData.StartTime).format("YYYY-MM-DD");
    let formattedEnd = moment(this.ModifiedData.EndTime).format("YYYY-MM-DD");
    let start = Date.parse(formattedStart);
    let end = Date.parse(formattedEnd);

    //getting dates between start-end dates
    let daylist = this.getDaysArray(formattedStart, formattedEnd);

    daylist.forEach(element => {
      let dayRange = moment(element).format("YYYY-MM-DD");
      this.dayRangeArray.push(dayRange);
    });
    //filtering weekdays from dayrange
    this.workingDays = this.dayRangeArray.filter(val => !this.holidays.includes(val));

    this.holidays.forEach(first => {
      if ((Date.parse(first) == start)) {
        this.startElement = true;
      }
    });
    this.holidays.forEach(second => {
      if ((Date.parse(second) == end)) {
        this.endElement = true;
      }
    });
    if (this.startElement && this.endElement && this.workingDays.length == 0) {
      this.checkDatesNext = true;
      return this.checkDatesNext;
    }
    return this.checkDatesNext;
  }

  getDaysArray(startingDate, endingDate) {
    for (var arr = [], dt = new Date(startingDate); dt <= new Date(endingDate); dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  }

  onValidFailed(event) {
    console.log(event)
  }

  // for the upload of data in the temp API 

  private tempPassesCheck(apiBody, visitorData) {
    if (visitorData.length > 0) {
      const passes = [];
      visitorData.forEach((visitor) => {
        const tempPass = { ...apiBody };
        tempPass.EntryType = !visitor.EntryType
          ? this.apiBody.EntryType
          : visitor.EntryType;
        tempPass.VisitorCompany = !visitor.VisitorCompany
          ? null
          : visitor.VisitorCompany;
        tempPass.VisitorEmail = !visitor.VisitorEmail
          ? null
          : visitor.VisitorEmail;
        tempPass.VisitorFirstName = !visitor.VisitorFirstName
          ? null
          : visitor.VisitorFirstName;
        tempPass.VisitorLastName = !visitor.VisitorLastName
          ? null
          : visitor.VisitorLastName;
        // tempPass.VisitorMiddleName = !visitor.VisitorMiddleName
        //   ? null
        //   : visitor.VisitorMiddleName;
        tempPass.VisitorMiddleName = "";
        tempPass.VisitorNameSuffix = !visitor.VisitorNameSuffix
          ? null
          : visitor.VisitorNameSuffix;
        tempPass.VisitorPhone = !visitor.VisitorPhone
          ? null
          : visitor.VisitorPhone.toString();
        tempPass.VisitorCountry = !visitor.VisitorCountry
          ? null
          : visitor.VisitorCountry;
        if (visitor.IgnoreDuplicate === true) tempPass.AllowDup = true;

        passes.push(tempPass);
      });
      const formattedData = this.formatBulkData(passes);
      this.rowData = [];
      this.passService
        .createTempPassCheck(formattedData).subscribe((res: any) => {
          this.rowData = res.body
        });
    }
  }

  onSelectionChanged($event) {
    const selectedRows = $event.api.getSelectedRows();
    if (selectedRows.length) {
      this.isRowSelected = true;
    } else {
      this.isRowSelected = false;
    }
  }

  isValidationDisabled(): boolean { 
    if(this.rowData.length > 0) 
      return this.rowData.some(row => !row.VisitorFirstName?.trim() || !row.VisitorLastName?.trim()); 
    else 
      return true;
  }
  onCellEditingStopped(event) {
    this.passService.updateTempPassCheck(event.data).subscribe((res: any) => {
      this.isValidationDisabled();
    })
  }
}
